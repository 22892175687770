/* eslint-disable import/order */
import { useEffect } from 'react';
import { ACCESS_TOKEN_EXPIRATION_TIME_LOCAL_STORAGE_KEY } from 'constant';
import moment from 'moment';

const AppLogout = ({ children }) => {
  const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

  const logoutAction = () => {
    localStorage.clear();
    window.location.pathname = '/login';
  };

  const handleLogoutTimer = () => {
    const currentTime = moment();
    const expireTime = moment(localStorage.getItem(ACCESS_TOKEN_EXPIRATION_TIME_LOCAL_STORAGE_KEY));
    if (expireTime.diff(currentTime, 'seconds') < 0) {
      logoutAction();
    }
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        handleLogoutTimer();
      });
    });
    handleLogoutTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default AppLogout;
