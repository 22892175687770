import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalComponent } from 'components';
import CompletedStepsAmico from 'assets/Completed-steps-amico.png';

import './style.css';

const ThreeStepstoYourNextIngeniousIdea = () => {
  const [modalShow, setModalShow] = useState(false);

  const modalOpen = () => {
    window.location.href = 'https://app.lemcal.com/@ideon/demo';
    // setModalShow(true);
  };

  const modalClose = () => {
    setModalShow(false);
  };
  return (
    <div className="white_threesteps_shadow_warper white_threeSteps_shadow_warper d-flex flex-column flex-md-row margin_threesteps_5 pb-5">
      <div className="width_threesteps_content_container responsive_threesteps_content ">
        <img
          src={CompletedStepsAmico}
          alt="CompletedStepsAmico"
          className="width_threesteps_80 reverce_threesteps_Margin justify-content-between mx-auto d-flex reverce_threesteps_responsive_image Manage_threesteps_Width"
        />
        <div className="center_threesteps_responsive">
          <h3 className="specail_threesteps_section width_threesteps_90 mt-3">3 steps to your next ingenious idea</h3>
          <p>Just 3 simple steps to optimize your brainstorming.</p>
          <button type="submit" className="baner_threesteps_btn" onClick={modalOpen}>
            Request Free Demo
          </button>
          {modalShow === true && <ModalComponent onClose={modalClose} />}
        </div>
      </div>
      <div className="width_threesteps_60 ps-5 pe-5 pb-1 justify-content-center m-auto responsive_threesteps_content">
        <div className="pb-3 border_threesteps_bottom pt-5">
          <h1 className="specail_threesteps_section">Set a topic</h1>
          <p>Provide a guidelines of exploration.</p>
        </div>
        <div className="pb-3 border_threesteps_bottom pt-5">
          <h1 className="specail_threesteps_section">Explore diverse aspects</h1>
          <p>
            Use our tools to generate an endless mind map with all the information you need. Collaborate with your team
            in the process.
          </p>
        </div>
        <div className="pb-3 pt-5">
          <h1 className="specail_threesteps_section">Find an idea</h1>
          <p>Build on top of your idea with tools and collaborators.</p>
        </div>
      </div>
    </div>
  );
};
ThreeStepstoYourNextIngeniousIdea.defaultProps = {};
ThreeStepstoYourNextIngeniousIdea.propTypes = {};

export default ThreeStepstoYourNextIngeniousIdea;
