/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable simple-import-sort/imports */
import React, { useRef, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Lottie from 'react-lottie';
import { useMutation, useQuery } from '@tanstack/react-query';
import Swal from 'sweetalert2';
import { formInitialValues, formValidation } from 'fetures/MyAccount/Components/CollaboratorModal/constant';
import { InputBox } from 'components';

import 'fetures/MyAccount/Components/CollaboratorModal/style.css';
import {
  createCompanyProfileCollaborator,
  deleteCompanyProfileCollaborator,
  getCompanyProfileCollaboratorData,
} from 'api/companyProfile';
import { catchBlock } from 'utils';
import animationTrash from 'assets/trash.json';

const CollaboratorModal = ({ companyProfileId, handleCloseCollaboratorModal }) => {
  const formRef = useRef();

  const [collaborators, setCollaborators] = useState([]);

  const { isFetching: isLoadingCompanyProfileCollaborator, refetch: refetchCompanyProfileCollaboratorData } = useQuery(
    ['get-company-profile-collaborator-data-by-id', companyProfileId],
    () => getCompanyProfileCollaboratorData(companyProfileId),
    {
      refetchOnMount: 'always',
      onSuccess: (response) => {
        setCollaborators(response?.data || []);
      },
      onError: (err) => {
        catchBlock(err);
      },
    },
  );

  const { mutate: createCompanyProfileCollaboratorApi, isLoading: createCompanyProfileCollaboratorLoading } =
    useMutation(createCompanyProfileCollaborator, {
      onSuccess: (response) => {
        refetchCompanyProfileCollaboratorData();

        if (formRef.current) {
          formRef.current.resetForm();
        }

        toast.success(response.message);
      },
      onError: (error) => {
        catchBlock(error);
      },
    });

  const { mutate: deleteCompanyCollaboratorApi, isLoading: isLoadingDeleteCompanyCollaborator } = useMutation(
    deleteCompanyProfileCollaborator,
    {
      onSuccess: (data, variables) => {
        refetchCompanyProfileCollaboratorData();

        Swal.fire('Deleted!', 'Collaborator record has been deleted.', 'success');
      },
      onError: (error) => {
        catchBlock(error);
      },
    },
  );

  const handleSubmitCollaboratorForm = (values) => {
    createCompanyProfileCollaboratorApi({
      email: values.email,
      company_profile_id: companyProfileId,
    });
  };

  const handleDeleteCollaborator = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCompanyCollaboratorApi(id);
      }
    });
  };

  const handleCloseModal = () => {
    handleCloseCollaboratorModal(collaborators.length || 0);
  };

  return (
    <Modal show centered onHide={handleCloseModal} className="myaccount_cmodal-container">
      <Modal.Body className="modal-body">
        <h2 className="modal-title">Share Company Profile</h2>

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidation}
          onSubmit={handleSubmitCollaboratorForm}
          innerRef={formRef}
        >
          {({ values, errors, touched, handleSubmit, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <div className="myaccount_cmodal-form-wrapper">
                <div className="myaccount_cmodal-form-group">
                  <Field
                    placeholder="Email"
                    name="email"
                    customClass="myaccount_cmodal-form-input"
                    onChange={handleChange}
                    component={InputBox}
                    type="email"
                    value={values?.email}
                  />
                  {errors.email && touched.email ? (
                    <div className="myaccount_cmodal-form-input-error">{errors.email}</div>
                  ) : null}
                </div>

                <button
                  type="submit"
                  className="myaccount_cmodal-form-button"
                  disabled={createCompanyProfileCollaboratorLoading}
                >
                  {createCompanyProfileCollaboratorLoading ? <Spinner animation="border" size="sm" /> : 'Send'}
                </button>
              </div>
            </Form>
          )}
        </Formik>

        <div className="myaccount_cmodal-collaborator-list-wrapper">
          <h4 className="myaccount_cmodal-collaborator-list-title">People with access</h4>

          <div className="myaccount_cmodal-collaborator-list">
            {isLoadingCompanyProfileCollaborator ? (
              <div className="myaccount_cmodal-collaborator-list-spinner">
                <Spinner animation="border" className="spiner" />
              </div>
            ) : collaborators.length <= 0 ? (
              <div className="myaccount_cmodal-collaborator-list-placeholder">
                <h4>No Collaborator.</h4>
              </div>
            ) : (
              <>
                {collaborators.map((collaborator, index) => (
                  <div key={index} className="myaccount_cmodal-collaborator-list-card">
                    <h6>{collaborator.email}</h6>

                    <button type="button" onClick={() => handleDeleteCollaborator(collaborator._id)}>
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: false,
                          animationData: animationTrash,
                        }}
                        height={25}
                        width={25}
                      />
                    </button>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

CollaboratorModal.defaultProps = {
  handleCloseCollaboratorModal: PropTypes.func,
  companyProfileId: PropTypes.string,
};

CollaboratorModal.propTypes = {
  handleCloseCollaboratorModal: PropTypes.func,
  companyProfileId: PropTypes.string,
};

export default CollaboratorModal;
