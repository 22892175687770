import React, { useEffect, useRef, useState } from 'react';
import CurrentWorkSpaceModal from './components/CurrentWorkSpaceModal';

import './style.css';

const CurrentWorkSpacePage = () => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="current_workplace_background text-white">
      <button type="button" className=" current_workplace_circletitleLogo" onClick={handleShow}>
        Ideon
      </button>
      {show === true && (
        <div ref={ref}>
          <CurrentWorkSpaceModal handleClose={handleClose} />
        </div>
      )}
    </div>
  );
};

export default CurrentWorkSpacePage;
