import React from 'react';
import AddSubscriptionDayComponent from 'fetures/AddSubscriptionDay';
import PageTitle from 'components/PageTitle';

const AddSubscriptionDay = () => {
  return (
    <>
      {/* <PageTitle title="Generate Link" /> */}

      <AddSubscriptionDayComponent />
    </>
  );
};

export default AddSubscriptionDay;
