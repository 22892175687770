import Api from './axios.config';

export const payment = async (data) => {
  const response = await Api.post('payment', data);
  return response.data;
};

export const priceDetailsQuery = async () => {
  const response = await Api.get('payment/get-price-detail');
  return response.data;
};

export const getUserSubscription = async () => {
  const response = await Api.get('payment/check-subscription-expire');
  return response.data;
};

export const getPaymentSubscriptionId = async (subscriptionId) => {
  const response = await Api.get(`payment/get-payment-detail/${subscriptionId}`);
  return response.data.data;
};

export const postPaymentsubscriptionId = async (obj) => {
  return new Promise((resolve) => {
    Api.post('payment/success-post', obj).then((res) => {
      resolve(res.data || {});
    });
  });
};

export const getPaymentSubscriptionExpire = async () => {
  const response = await Api.get('payment/check-subscription-expire');
  return response?.data?.data;
};

export const fetchAllSubscriptions = async () => {
  const response = await Api.get('payment/fetch_subscription');
  return response?.data?.data;
};

export const inviteUserSubscription = async (data) => {
  const response = await Api.post('payment/save_slot', data);
  return response?.data;
};

export const addSubscription = async (data) => {
  const response = await Api.get(`payment/add_subscription?sid=${data.sid}`);
  return response?.data;
};

export const removeUserSubscription = async (data) => {
  const response = await Api.post('payment/remove_slot', data);
  return response?.data;
};

export const addSubscriptionDays = async (data) => {
  const response = await Api.post('payment/add_subscription_days', data);
  return response?.data;
};
