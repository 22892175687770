/* eslint-disable simple-import-sort/imports */
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { routes, TOAST_CONFIG } from 'constant';
import { CompanyObjectForm } from 'fetures/CompanyProfile/Components';
import { TopNavigationBar } from 'components';
import { getCompanyObject, getCompanyProfileEditData, updateCompanyProfile } from 'api/companyProfile';
import { catchBlock } from 'utils';

import 'fetures/CompanyProfile/style.css';

const CompanyProfileEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [companyAttributes, setCompanyAttributes] = useState([]);
  const [companyObject, steCompanyObject] = useState({});
  const [companyObjectFormInitialValues, setCompanyObjectFormInitialValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { isFetching: isLoadingCompanyProfile } = useQuery(
    ['get-company-profile-edit-data-by-id', id],
    () => getCompanyProfileEditData(id),
    {
      refetchOnMount: 'always',
      onSuccess: (res) => {
        setCompanyAttributes(res?.data ? res?.data?.attributes || [] : []);
        steCompanyObject(res?.data ? res?.data?.companyObject || {} : {});

        setCompanyObjectFormInitialValues({
          attributes: Object.keys(res?.data?.companyObject || {}).reduce((acc, key) => {
            const foundAttribute = (res?.data?.attributes || []).find((a) => a.objectKey === key);
            if (foundAttribute) {
              acc[key] = res?.data?.companyObject[key];
            }
            return acc;
          }, {}),
        });
      },
      onError: (err) => {
        catchBlock(err);
      },
    },
  );

  const { mutate: updateCompanyProfileApi, isLoading: updateCompanyProfileLoading } = useMutation(
    updateCompanyProfile,
    {
      onSuccess: (res) => {
        toast.success(res.message, TOAST_CONFIG);
        setTimeout(() => {
          navigate(routes.myAccount);
        }, 800);
      },
      onError: (error) => {
        catchBlock(error);
      },
    },
  );

  const handleSubmitCompanyObjectForm = async (values) => {
    try {
      const attributes = values?.attributes || {};
      const websiteChanged = attributes.website && attributes.website !== companyObject?.website;

      const prepareRequestData = (companyObjectData) => {
        return {
          attributes: companyAttributes,
          companyObject: { ...companyObject, ...companyObjectData },
          name: companyObjectData?.name || '',
        };
      };

      if (websiteChanged) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'Do you want to refresh the website data?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, refresh it!',
        }).then(async (result) => {
          setIsLoading(true);

          if (result.isConfirmed) {
            const requestData = Object.keys(attributes).map((key) => ({
              value: attributes[key],
              objectKey: key,
            }));

            const res = await getCompanyObject({ attributes: requestData });
            const updatedRequestData = prepareRequestData(res?.companyObject);

            await updateCompanyProfileApi({ id, data: updatedRequestData });
          } else {
            const companyObjectData = { ...attributes };
            const requestData = prepareRequestData(companyObjectData);

            await updateCompanyProfileApi({ id, data: requestData });
          }
        });
      } else {
        setIsLoading(true);

        const companyObjectData = { ...attributes };
        const requestData = prepareRequestData(companyObjectData);

        await updateCompanyProfileApi({ id, data: requestData });
      }
    } catch (error) {
      catchBlock(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {(isLoadingCompanyProfile || updateCompanyProfileLoading || isLoading) && (
        <div className="cp-spinner-wrapper">
          <Spinner animation="border" className="cp-spiner" />
        </div>
      )}

      <div className="bg-transparent cp-container">
        <div className="cp-content">
          <TopNavigationBar isShowCompanyDropdown={false} logoLink={routes.myAccount} />

          <Formik
            onSubmit={handleSubmitCompanyObjectForm}
            initialValues={companyObjectFormInitialValues}
            enableReinitialize
          >
            <CompanyObjectForm formTitle="EDIT COMPANY PROFILE" attributes={companyAttributes} />
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CompanyProfileEdit;
