import React from 'react';
import AboutUsComponent from 'fetures/AboutUs';
import PageTitle from 'components/PageTitle';

const AboutUsPage = () => {
  return (
    <>
      <PageTitle title="About Us" />

      <AboutUsComponent />
    </>
  );
};

export default AboutUsPage;
