import { toast } from 'react-toastify';
import { ACCESS_TOKEN_LOCAL_STORAGE_KEY, TOAST_CONFIG, USER_DATA_LOCAL_STORAGE_KEY } from 'constant';

export const checkUserAuth = () => {
  const user = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  return user || '';
};

export const getUserData = () => {
  const userData = JSON.parse(localStorage.getItem(USER_DATA_LOCAL_STORAGE_KEY));
  return userData || '';
};

export const catchBlock = (error) => {
  if (error.response) {
    toast.error(error.response.data.message, TOAST_CONFIG);
  } else if (typeof error.error !== 'undefined' && typeof error.error.message !== 'undefined') {
    toast.error(error.error.message, TOAST_CONFIG);
  } else {
    toast.error(error.message, TOAST_CONFIG);
  }
};

export const formatDate = '';
