/* eslint-disable simple-import-sort/imports */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { Field, Form, Formik } from 'formik';
import Swal from 'sweetalert2';
import { TOAST_CONFIG } from 'constant';
import { inviteUserSubscription, removeUserSubscription } from 'api/payment';
import { InviteSubInitialValues, InviteSubValidation } from './constant';

const InviteForm = ({ n, account, refetchSlots }) => {
  const [initVals] = useState({ ...InviteSubInitialValues, ...{ slot: n, emailId: account?.emailId || '' } });
  const [workcardId, setWorkcardId] = useState(-1);
  const [deleteWorkcardId, setDeleteWorkcardId] = useState(-1);
  const { mutate: sendInviteMutate, isLoading } = useMutation(inviteUserSubscription, {
    onSuccess: (data) => {
      refetchSlots();
      toast.success(data?.message, TOAST_CONFIG);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });
  const { mutate: removeInviteMutate, isLoadingRemove } = useMutation(removeUserSubscription, {
    onSuccess: (data) => {
      refetchSlots();
      // toast.success(data?.message, TOAST_CONFIG);
      Swal.fire('Deleted!', data?.message, 'success');
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const InvitePost = useCallback(
    (values) => {
      const requestValues = {
        email: values.emailId,
        slot: values.slot,
      };
      sendInviteMutate(requestValues);
    },
    [sendInviteMutate],
  );

  const removeSlot = useCallback(() => {
    removeInviteMutate(initVals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeInviteMutate]);

  const successAlert = (id) => {
    setDeleteWorkcardId(id);
    setTimeout(() => {
      setDeleteWorkcardId(-1);
    }, 950);
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        removeSlot();
      }
    });
  };
  return (
    <div className="col-md-6 p-3">
      <Formik enableReinitialize initialValues={initVals} validationSchema={InviteSubValidation} onSubmit={InvitePost}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-group row">
              <div className="col-md-6 p-1">
                <Field
                  className={`form-control-plaintext bg-white p-1 rnd_input ${initVals.emailId !== '' ? 'filled' : ''}`}
                  name="emailId"
                  placeholder="Add user"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // component={InputBox}
                  style={{
                    lineHeight: '1.7',
                  }}
                  disabled={initVals.emailId !== ''}
                />
                {errors.emailId && touched.emailId ? (
                  <div className="login_lightRed d-block justify-content-center mx-auto text-start mt-2">
                    {errors.emailId}
                  </div>
                ) : null}
              </div>
              <div className="col-md-3 p-1 px-3">
                {initVals.emailId === '' ? (
                  <button type="submit" className="w-100 workspace-modal-button sub_button" disabled={isLoading}>
                    {isLoading ? <Spinner animation="border" className="form_loader" /> : 'Invite'}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="w-100 workspace-modal-button sub_button"
                    disabled={isLoadingRemove}
                    onClick={successAlert}
                  >
                    {isLoadingRemove ? <Spinner animation="border" className="form_loader" /> : 'Remove'}
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InviteForm;
