// eslint-disable-next-line simple-import-sort/imports
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routes } from 'constant';

import './style.css';

const TitleLogo = ({ redirectTo }) => {
  return (
    <div>
      <Link to={redirectTo || routes.home} className="text-decoration-none">
        <h4 className="circletitleLogo">
          Ideon<span>AI</span>
        </h4>
      </Link>
    </div>
  );
};

TitleLogo.defaultProps = {
  redirectTo: null,
};

TitleLogo.propTypes = {
  redirectTo: PropTypes.string,
};

export default TitleLogo;
