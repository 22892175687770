import React from 'react';
import CompanyProfile from 'fetures/CompanyProfile';
import PageTitle from 'components/PageTitle';

const CompanyProfilePage = () => {
  return (
    <>
      <PageTitle title="Company Profile" />
      <CompanyProfile />
    </>
  );
};

export default CompanyProfilePage;
