/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Lottie from 'react-lottie';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { routes, USER_DATA_LOCAL_STORAGE_KEY } from 'constant';
import { getPaymentSubscriptionExpire, getPaymentSubscriptionId, postPaymentsubscriptionId } from 'api/payment';
import { catchBlock } from 'utils';
import CorrectIcon from '../../assets/correct.json';

// eslint-disable-next-line import/order
import './style.css';

const PaymentSuccessUiFetures = () => {
  const [enable, setEnable] = useState(null);
  const [paymentData, setPaymentData] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const { isLoading } = useQuery(
    ['getPaymentSubscriptionId'],
    () => getPaymentSubscriptionId(params.checkout_session),
    {
      onSuccess: (response) => {
        const paymentRequestData = {
          checkout_session_id: response.id,
          subscription_id: response.subscription,
        };
        paymentRequst(paymentRequestData);
      },
      onError: (err) => {
        catchBlock(err);
      },
    },
  );

  const { mutate: paymentRequst, isLoading: deleteLoader } = useMutation(postPaymentsubscriptionId, {
    onSuccess: (data) => {
      setPaymentData(data.data);
      setEnable(true);
      const userObj = JSON.parse(localStorage.getItem(USER_DATA_LOCAL_STORAGE_KEY));
      userObj.is_free_user = false;
      localStorage.setItem(USER_DATA_LOCAL_STORAGE_KEY, JSON.stringify(userObj));
      setTimeout(() => {
        navigate(routes.dashBoard);
      }, 3000);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const { data: mainData } = useQuery(['getPaymentSubscriptionExpire'], () => getPaymentSubscriptionExpire(), {
    enabled: !!enable,
    onSuccess: (res) => {
      const userObj = JSON.parse(localStorage.getItem(USER_DATA_LOCAL_STORAGE_KEY));
      userObj.is_sub_user = res.qty < 1;
      localStorage.setItem(USER_DATA_LOCAL_STORAGE_KEY, JSON.stringify(userObj));
    },
  });

  return isLoading ? (
    <div className="spinner-div">
      <Spinner animation="border" className="spiner" />
    </div>
  ) : (
    <div className="Payement_success_main_container">
      <div className="Payement_success_part1_container">
        <div className="Payement_success_part1_container_section">
          <div className="Payement_success_part1_section">
            <div className="Payement_success_heading">
              <Link
                to={routes.dashBoard}
                className="Payement_success_ideon_logo"
                onClick={() => {
                  navigate(routes.dashBoard);
                }}
              >
                Ideon
              </Link>

              <Link to={routes.dashBoard} className="Payement_success_ideon_heading">
                IDEON
              </Link>
            </div>
            <div className="try_basic_section">
              <h6 className="try_basic">Plan</h6>
              <h1 className="try_basic_heading">Basic</h1>
              {/* <h6 className="try_basic_worth">
                Then {mainData?.currency}
                {mainData?.items?.data[0].price?.unit_amount / 100} per month
              </h6> */}
            </div>
            <div>
              <div className="d-flex py-4">
                <div className="pe-5 width_basic_70 payment_success_font_size">Basic</div>
                <div className="ps-5">
                  {/* <span className="d-block payment_success_font_size">7 days feee</span> */}
                  <span className="dull_white payment_success_font_size_gray">
                    {paymentData?.currency} {paymentData?.amount} / {paymentData?.interval} after
                  </span>
                </div>
              </div>
              {/* <div className="d-flex py-3">
                <div className="pe-5 width_basic_70 payment_success_font_size">total after trial</div>
                <div className="ps-5">
                  <span className="payment_success_font_size">
                    {mainData?.currency} {mainData?.items?.data[0].price?.unit_amount / 100}
                  </span>
                </div>
              </div> */}
              {/* <div className="d-flex py-3">
                <div className="pe-5 width_basic_70 payment_success_font_size">total after today</div>
                <div className="ps-5">
                  <span className="payment_success_font_size">&#8364;0.00</span>
                </div>
              </div> */}
            </div>
            <div className="Payement_success_footer mt-5">
              <span className="pe-5 ">
                <Link to={routes.home} className="payment_success_font_size_gray">
                  Terms
                </Link>
              </span>
              <span className="">
                <Link to={routes.home} className="payment_success_font_size_gray">
                  Privacy
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="Payement_success_part2_container">
        <div className="payment_seccess_part2_section">
          <div className="">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: CorrectIcon,
              }}
              height={190}
              width={190}
            />
            {/* <MdDone className="success_payment_done" /> */}
          </div>
          <div className="success_payment_part2_heading_div">
            <h1>Thanks for subcribing</h1>
            <p className="success_payment_part2_paragraph">
              After your trial, a payment to Stripe will appear on your statement.
            </p>
          </div>
          <div className="d-flex seccess_payment_stripe_card">
            <h6 className="seccess_payment_stripe_card_text seccess_payment_stripe_card_text_size success_payment_stripechard_border">
              Stripe
            </h6>
            <h6 className="seccess_payment_stripe_card_text_size">
              {paymentData?.currency} {paymentData?.amount}
            </h6>
          </div>
          You'll be redirected to dashboard in few seconds or click below
          <br />
          <Link
            to={routes.dashBoard}
            className="my-dashboard-link"
            onClick={() => {
              navigate(routes.dashBoard);
            }}
          >
            Go to My Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessUiFetures;
