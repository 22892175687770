import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'constant';

import './style.css';

const Footer = () => {
  return (
    <div className="darkGrayBg ">
      <div className="footer_container">
        <div className="d-flex  footer_width justify-content-between  m-auto d-flex flex-column flex-sm-row responsive_Footer">
          <div>
            <h1 className="footer-logo-text">Ideon</h1>
            <div className="">
              <p className="footer-text">2024 © Ideon Ltd</p>
              <p className="footer-text">All rights reserved.</p>
            </div>
          </div>
          <div>
            <ul className="list-unstyled">
              <li className="mb-2">
                <Link to={routes.home} className="links-btns">
                  Home
                </Link>
              </li>
              <li className="mb-2">
                <Link to={routes.homeAbout} className="links-btns">
                  About us
                </Link>
              </li>
              <li>
                <Link to={routes.homeContact} className="links-btns">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <ul className="list-unstyled">
              <li>
                <Link to="https://www.linkedin.com/company/ideon-app/" className="links-btns">
                  LinkedIn
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <ul className="list-unstyled">
              <li className="mb-2">
                <Link to={routes.privacyPolicy} className="links-btns">
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link to={routes.termsAndCondition} className="links-btns">
                  Terms Of Services
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
