import React, { useState } from 'react';
import { ModalComponent } from 'components';
// import { ModalComponent } from 'components';
import innovationAmico from 'assets/Innovation-amico.png';

import './style.css';

const IntroducingaNewWayOfIdeation = () => {
  const [modalShow, setModalShow] = useState(false);

  const modalOpen = () => {
    window.location.href = 'https://app.lemcal.com/@ideon/demo';
    // setModalShow(true);
  };

  const modalClose = () => {
    setModalShow(false);
  };
  return (
    <div className="steps_section">
      <div className="steps_section_containe">
        <div className="md_no steps_section_containe_one">
          <h2 className="md_no_one revolution_title">1</h2>
          <h6 className="md_card_text child_titles_text">Explain challenge</h6>
        </div>
        <div className="md_no steps_section_containe_two">
          <h2 className="md_no_two revolution_title">2</h2>
          <h6 className="md_card_text child_titles_text">Provide website</h6>
        </div>
        <div className="md_no steps_section_containe_three">
          <h2 className="md_no_three revolution_title">3</h2>
          <h6 className="md_card_text child_titles_text">Explore tailored solutions</h6>
        </div>
        <div className="md_no steps_section_containe_four">
          <h2 className="md_no_four revolution_title">4</h2>
          <h6 className="md_card_text child_titles_text">Generate action steps</h6>
        </div>
        <div className="md_no steps_section_containe_five">
          <h2 className="md_no_five revolution_title">5</h2>
          <h6 className="md_card_text child_titles_text">Export strategy to PDF</h6>
        </div>
      </div>
    </div>
    // <div className="white_introducing_shadow_warper my-5">
    //   <div className="width_introducing_main_container d-flex flex-column flex-md-row justify-content-center m-auto pt-1">
    //     <div className="width_introducing_60 Responsive_introducing_Content height_introducing_manager">
    //       <h1 className="sub_title_introducing_drak w-75 Width_introducing_Full Width_introducing_Management_Heading">
    //         Introducing <br /> a new way of ideation
    //       </h1>
    //       <p>Think out of the box. Collaborate. Innovate.</p>
    //       <button type="button" className="baner_introducing_btn" onClick={modalOpen}>
    //         Request Demo
    //       </button>
    //       {modalShow === true && <ModalComponent onClose={modalClose} />}
    //     </div>
    //     <div className="width_introducing_70  responsive_introducing_Image">
    //       <img src={innovationAmico} alt="innovationAmico" className="w-100" />
    //     </div>
    //   </div>
    // </div>
  );
};

export default IntroducingaNewWayOfIdeation;
