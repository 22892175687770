/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState } from 'react';
import { FormSelect, Modal, Spinner } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AiOutlineLink } from 'react-icons/ai';
import Lottie from 'react-lottie';
import { toast } from 'react-toastify';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { InputBox } from 'components';
import { addCollabrater, createCollabraterShareLink, deleteCollabraterUser, getAllCollabrater } from 'api/Collabrator';
import { ModalConfirmationInitialValues, ModalConfirmationValidation } from './constant';
// eslint-disable-next-line import/order
import animationTrash from 'assets/trash.json';

import './style.css';

const ModalConfirmation = ({ workSpaceData, closeoModalConformation, refetchWorkspace }) => {
  const formRef = useRef();

  const [msg, setMsg] = useState('Anyone on the Internet with the link can view');
  const [workspaceLink, setWorkspaceLink] = useState(
    workSpaceData.access_link !== null ? `workspace/${workSpaceData.access_link}` : '',
  );

  const [generalAccessShow, setGeneralAccessShow] = useState(workSpaceData.access_link !== null);

  // const [collabrators, setCollabrators] = useState();

  const changeLanguage = (event) => {
    const createLinkData = {
      work_space_id: workSpaceData._id,
      access_type: 'viewer',
      is_create: true,
    };
    if (event.target.selectedIndex === 0) {
      setMsg('Anyone on the Internet with the link can view');
    } else if (event.target.selectedIndex === 1) {
      createLinkData.access_type = 'editor';
      setMsg('Anyone on the Internet with the link can edit');
    } else if (event.target.selectedIndex === 2) {
      setMsg('Anyone on the Internet with the link can comment');
    }
    createCollabraterShareLinkMutate(createLinkData);
  };

  const {
    isLoading,
    data: collabrators,
    refetch: refetchCollaborator,
  } = useQuery({
    cacheTime: 0,
    queryKey: workSpaceData._id,
    queryFn: getAllCollabrater,
    // onSuccess: (res) => {
    //   setCollabrators(res?.data);
    // },
    onError: (err) => {
      catchBlock(err);
    },
  });

  const DeleteAlert = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: 'confirm-button-class',
        title: 'title-class',
        icon: 'icon-class',
        preConfirm: 'swal-disable',
      },
      preConfirm: async () => {
        Swal.disableButtons();
        return new Promise((resolve, reject) => {
          deletCollabrator(id, {
            onSuccess: () => {
              resolve(true);
            },
            onError: (err) => {
              reject(err);
            },
          });
        }).catch((error) => {
          let errorMessage = '';
          if (typeof error?.response?.data?.Error !== 'undefined' && error?.response?.data?.Error !== '') {
            errorMessage = error?.response?.data?.Error || 'Something went wrong';
          } else if (
            typeof error?.response?.data?.DUPLICATE !== 'undefined' &&
            error?.response?.data?.DUPLICATE !== ''
          ) {
            errorMessage = error?.response?.data?.DUPLICATE || 'Something went wrong';
          } else if (typeof error?.response?.data?.ERROR !== 'undefined' && error?.response?.data?.ERROR !== '') {
            errorMessage = error?.response?.data?.ERROR || 'Something went wrong';
          } else if (typeof error?.response?.data?.Message !== 'undefined' && error?.response?.data?.Message !== '') {
            errorMessage = error?.response?.data?.Message || 'Something went wrong';
          } else if (typeof error?.response?.data !== 'undefined' && error?.response?.data !== '') {
            errorMessage = error?.response?.data || 'Something went wrong';
          } else {
            errorMessage = error?.message || 'Something went wrong';
          }
          Swal.enableButtons(true);
          Swal.showValidationMessage(errorMessage);
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  };

  const { mutate: deletCollabrator, isLoading: deleteLoader } = useMutation(deleteCollabraterUser, {
    onSuccess: (data, variables) => {
      toast.success(data.message);
      refetchCollaborator();
      // const filteredResult = collabrators?.filter((item) => item._id !== variables);
      // setCollabrators(filteredResult);
      refetchWorkspace();
    },

    onError: (error) => {
      catchBlock(error);
    },
  });

  const { mutate: createCollabrater, isLoading: postDataLoading } = useMutation(addCollabrater, {
    onSuccess: (data, variables) => {
      // const checkEmail = collabrators.find((item) => item.email === variables.email);
      // if (checkEmail === undefined && variables.role === undefined) {
      //   variables.role = 'viewer';
      //   variables._id = data.data._id;
      //   collabrators.push(variables);
      // }
      refetchCollaborator();
      refetchWorkspace();
      toast.success(data.message);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const { mutate: createCollabraterShareLinkMutate, isLoading: postCreateLinkLoading } = useMutation(
    createCollabraterShareLink,
    {
      onSuccess: (data) => {
        setWorkspaceLink(data.link);
        toast.success(data.message);
        refetchWorkspace();
      },
      onError: (error) => {
        setWorkspaceLink('');
        catchBlock(error);
      },
    },
  );

  const CollabraterFun = useCallback(
    (values) => {
      const checkUser = collabrators?.data?.find((user) => user.email === values.email);
      const addRequestValue = {
        work_space_id: workSpaceData._id,
        email: values.email,
      };

      if (values.role !== null || undefined) {
        addRequestValue.role = values.role;
      }

      createCollabrater(addRequestValue, {
        onSuccess: () => {
          if (formRef.current) {
            formRef.current.resetForm();
          }
        },
      });
    },
    [collabrators, createCollabrater, workSpaceData],
  );

  return (
    <Modal show centered onHide={closeoModalConformation} className="main-modal-containe">
      <Modal.Body className="workspace-modal-body">
        <h2 className="workspace-modal-title">Share Workspace</h2>

        <div>
          <Formik
            initialValues={ModalConfirmationInitialValues}
            validationSchema={ModalConfirmationValidation}
            onSubmit={CollabraterFun}
            innerRef={formRef}
          >
            {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div className="workspace-modal-input-btn-div">
                  <div className="workspace-modal-form-input-div">
                    <Field
                      placeholder="Email"
                      name="email"
                      customClass="extraclass"
                      onChange={handleChange}
                      component={InputBox}
                      type="email"
                      value={values?.email}
                    />
                    {errors.email && touched.email ? (
                      <div
                        className="lightRed d-block d-absolute justify-content-center text-start"
                        style={{ position: 'absolute' }}
                      >
                        {errors.email}
                      </div>
                    ) : null}
                  </div>

                  <button type="submit" className="workspace-modal-button" disabled={isSubmitting}>
                    {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Send'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div>
          <h4 className="workspace-modal-h4">People with access</h4>

          <div className="scroolbar-div">
            {isLoading ? (
              <div className="modal-spinner-div">
                <Spinner animation="border" className="spiner" />
              </div>
            ) : collabrators?.data === undefined || collabrators?.data?.length === 0 ? (
              <div className="no-data-div">
                <h4 className="workspace-modal-nodetails">No Collaborator.</h4>
              </div>
            ) : (
              collabrators?.data?.map((user) => {
                return (
                  <div key={user.id} className="workspace-modal-gmail-box">
                    <h6 className="workspace-modal-gmail-box-h6">{user.email}</h6>

                    <div className="workspace-modal-gmail-box-select-box">
                      <select
                        onChange={(e) => {
                          CollabraterFun({ ...user, role: e.target.value });
                        }}
                        className="modal-custome-select-box"
                      >
                        <option value={user.role}>{user.role === 'viewer' ? 'Viewer' : 'Editor'} </option>

                        {user.role !== 'editor' && <option value="editor">Editor</option>}

                        {user.role !== 'viewer' && <option value="viewer">Viewer</option>}
                      </select>

                      <button
                        type="button"
                        className="workspace-modal-gmail-box-button"
                        onClick={() => {
                          DeleteAlert(user._id);
                        }}
                      >
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: false,
                            animationData: animationTrash,
                          }}
                          height={25}
                          width={25}
                        />
                      </button>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div>
          <h4 className="workspace-modal-h4">General access</h4>

          <div className="drop-downs-flex">
            <div className="restrict-select-box">
              <select
                className="modal-custome-select-box"
                onChange={(event) => {
                  const createLinkData = {
                    work_space_id: workSpaceData._id,
                    access_type: 'viewer',
                    is_create: true,
                  };
                  if (event.target.value !== 'restricted') {
                    createCollabraterShareLinkMutate(createLinkData);
                    setGeneralAccessShow(true);
                  } else {
                    createLinkData.is_create = false;
                    createCollabraterShareLinkMutate(createLinkData);
                    setGeneralAccessShow(false);
                  }
                }}
              >
                <option
                  selected={workSpaceData.access_link === null}
                  disabled={postCreateLinkLoading}
                  className="modal-custome-select-option-box"
                  value="restricted"
                >
                  Restricted
                </option>

                <option
                  selected={workSpaceData.access_link !== null}
                  disabled={postCreateLinkLoading}
                  className="modal-custome-select-option-box"
                  value="Anyone with the Link"
                >
                  Anyone with the Link
                </option>
              </select>
            </div>

            {generalAccessShow === true && (
              <div
                className="workspace-modal-General-box-select-box"
                style={{ display: '-webkit-inline-box', gap: '5px' }}
              >
                <select
                  className="modal-custome-select-box"
                  onChange={(event) => {
                    changeLanguage(event);
                  }}
                >
                  <option
                    selected={workSpaceData.linkSharedAccess === 'viewer'}
                    disabled={postCreateLinkLoading}
                    value="Viewer"
                  >
                    Viewer
                  </option>
                  <option
                    selected={workSpaceData.linkSharedAccess === 'editor'}
                    disabled={postCreateLinkLoading}
                    value="Editor"
                  >
                    Editor
                  </option>
                </select>
                <CopyToClipboard
                  text={`${process.env.REACT_APP_BASE_URL}/${workspaceLink}`}
                  onCopy={() => toast.success('Link copied to clipboard.')}
                >
                  <AiOutlineLink
                    data-tooltip-id="copy-link-button-tooltip-id"
                    color="#fff"
                    size="40px"
                    fontWeight="bold"
                    className="copyLinkIcon"
                  />
                </CopyToClipboard>
                <ReactTooltip
                  id="copy-link-button-tooltip-id"
                  className="copy-button-tooltip"
                  style={{
                    color: '#F05436',
                    background: 'white',
                    fontWeight: 'bold',
                    font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                  }}
                  place="bottom"
                  content="Copy Link"
                />
              </div>
            )}
          </div>

          <p className="workspace-modal-General-msg">
            {generalAccessShow ? msg : 'Only people with access can open with the link'}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ModalConfirmation.defaultProps = {
  closeoModalConformation: PropTypes.func,
  refetchWorkspace: PropTypes.func,
  workSpaceData: PropTypes.object,
};

ModalConfirmation.propTypes = {
  closeoModalConformation: PropTypes.func,
  refetchWorkspace: PropTypes.func,
  workSpaceData: PropTypes.object,
};
export default ModalConfirmation;
