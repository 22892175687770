import React from 'react';
import GetStartedWithIdeonToday from 'components/GetStartedWithIdeonToday';
import { AboutUs, SuccessIsMission } from './Components';

import './style.css';

const index = () => {
  return (
    <div>
      <AboutUs />

      <SuccessIsMission />

      <div className="width_about_get_start_container justify-content-center m-auto">
        <GetStartedWithIdeonToday />
      </div>
    </div>
  );
};

export default index;
