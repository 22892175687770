import axios from 'axios';
import Api from './axios.config';

export const login = async (data) => {
  const response = await Api.post('auth/login', data);
  return response.data;
};

export const socialLogin = async (data) => {
  const response = await Api.post('auth/social-login', data);
  return response.data;
};

export const googleLoginApi = async (headers, accessToken) => {
  const response = await axios.get(`${process.env.REACT_APP_GOOGLE_API_URL}?access_token=${accessToken}`, {
    headers,
  });
  return response.data;
};

export const register = async (data) => {
  const response = await Api.post('auth/register', data);
  return response.data;
};

export const forgotPasswordPost = async (data) => {
  const response = await Api.post('auth/forgot-password', data);
  return response.data;
};

export const resetPasswordPost = async (data) => {
  const response = await Api.post('auth/reset-password', data);
  return response.data;
};

export const checkResetPasswordToken = async (data) => {
  const token = data.queryKey[1];
  const response = await Api.get(`auth/forgot-password-verification-code?forgotten_password_code=${token}`);
  return response.data;
};

export const sendEnquiry = async (data) => {
  const response = await Api.post('auth/send-inquiry', data);
  return response.data;
};
