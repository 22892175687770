import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Button = (props) => {
  const { children, onClick, ...rest } = props;
  return (
    <button type="submit" {...rest} className="loginBtn submitButton" onClick={onClick}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  onClick: () => {},
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
};

export default Button;
