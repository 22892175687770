/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

export const ContactValidation = Yup.object()
  .shape({
    email: Yup.string()
      .email()
      .min(2, 'Email address too Short!')
      .max(50, 'Email address too Long!')
      .required('Kindly enter a valid email address!'),
  })
  .defined();

export const ContactInitialValues = {
  email: '',
};
