import React from 'react';
import { ContactForm, ContactImage } from './Components';

import './style.css';

const index = () => {
  return (
    <div className="contact_page">
      <div className="contact-page-container">
        <ContactImage />

        <ContactForm />
      </div>
    </div>
  );
};

export default index;
