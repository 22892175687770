import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { REFERRAL_CODE_LOCAL_STORAGE_KEY, routes } from 'constant';
import { referralCodeExisting } from 'api/referralLink';

import './style.css';

const ReferralComponent = () => {
  const { code } = useParams();
  const getCheakReferralCode = useMutation(referralCodeExisting);
  const navigate = useNavigate();

  useEffect(() => {
    getCheakReferralCode.mutate(code, {
      onSuccess: (data) => {
        if (data.data) {
          localStorage.setItem(REFERRAL_CODE_LOCAL_STORAGE_KEY, code);
          navigate(`${routes.register}?cd=65bb76009a551920409b8733`);
        } else {
          localStorage.removeItem(REFERRAL_CODE_LOCAL_STORAGE_KEY);
          navigate(`${routes.register}?er=65bb76009a551920409b8733`);
        }
      },
      onError: (error) => {
        // console.log('Error', error);
        navigate(`${routes.register}?er=65bb76009a551920409b8733`);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return <div className="referral-code-page"> </div>;
};

export default ReferralComponent;
