import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import store from 'app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 1000,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <HelmetProvider>
      <React.StrictMode>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <ToastContainer />
            <App />
          </QueryClientProvider>
        </Provider>
      </React.StrictMode>
    </HelmetProvider>
  </GoogleOAuthProvider>,
);

reportWebVitals();
