import React, { useEffect, useState } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import PropTypes from 'prop-types';

import './style.css';

const TextAreaInput = ({ placeholder, field, type, form: { setFieldValue } }) => {
  const [localStateVal, setLocalStateVal] = useState(field.value);

  useEffect(() => {
    setLocalStateVal(field.value);
  }, [field.value]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFieldValue(field.name, localStateVal);
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [field.name, localStateVal, setFieldValue]);

  return (
    <textarea
      className="d-block justify-content-center fs-7 my-2 textArea"
      placeholder={placeholder}
      {...field}
      type={type}
      value={localStateVal}
      onChange={(e) => setLocalStateVal(e.target.value)}
    />
  );
};

TextAreaInput.defaultProps = {
  placeholder: PropTypes.func,
  field: PropTypes.any,
  form: PropTypes.object,
};

TextAreaInput.propTypes = {
  placeholder: PropTypes.string,
  field: PropTypes.any,
  form: propTypes.object,
  type: PropTypes.string.isRequired,
};

export default TextAreaInput;
