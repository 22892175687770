import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  password: Yup.string().required('Please enter new password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Please enter confirm password'),
});

export const initialValues = {
  password: '',
  confirmPassword: '',
};
