import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { IoIosMenu } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { routes } from 'constant';
import { TitleLogo } from 'components';

import './style.css';

const Mobile = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="toggleBtnDiv">
      <button type="button" className="toggleBtn" onClick={handleShow}>
        <IoIosMenu color="#000000" size="40px" />
      </button>

      {show === true && (
        <div className="toggleOptions">
          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <TitleLogo />
            </Offcanvas.Header>

            <Offcanvas.Body>
              <div className="linkBtnDiv">
                <Link to={routes.dashBoard} className="togglelinkBtn">
                  DashBoard
                </Link>
              </div>

              <button type="button" className="toggle-logout-btn">
                Logout
              </button>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      )}
    </div>
  );
};

export default Mobile;
