/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import Select from 'react-select';
import { Field, Form, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { catchBlock } from 'utils';

import 'fetures/CompanyProfile/Components/CompanyObjectForm/style.css';

const CompanyObjectForm = ({ formTitle, attributes }) => {
  const { handleSubmit, setFieldValue, values } = useFormikContext();

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const handleConfirmFormStep = async () => {
    try {
      setIsConfirmLoading(true);

      let isValid = true;

      attributes.forEach((attribute) => {
        if (!values?.attributes?.[attribute.objectKey] && !attribute.isOptional) {
          isValid = false;
        }
      });

      if (isValid) {
        await handleSubmit();
      }
    } catch (error) {
      catchBlock(error);
    } finally {
      setIsConfirmLoading(false);
    }
  };

  return (
    <div className="cp_co_form-containers">
      <Form onSubmit={handleSubmit}>
        <div className="cp_co_form-main-container">
          <div className="cp_co_form-title-wrapper">
            <p className="cp_co_form-title">- {formTitle || ''}</p>
          </div>

          <div className="cp_co_form-body">
            <div className="cp_co_form-content">
              {attributes &&
                attributes.map((attribute, index) => (
                  <div className="cp_co_form-element" key={`cp_co_form-attribute${index}`}>
                    <h4>
                      {attribute?.title || ''}
                      {attribute?.isOptional && (
                        <span className="cp_co_form-optional-label">
                          <i>(Optional)</i>
                        </span>
                      )}
                    </h4>

                    <div className={`cp_co_form-element-wrapper ${attribute?.inputType}`}>
                      {attribute?.inputType === 'dropdown' ? (
                        <Select
                          name={`attributes[${attribute?.objectKey}]`}
                          options={
                            attribute?.values?.reduce((attributes, attribute) => {
                              attributes.push({
                                label: attribute,
                                value: attribute === '-' ? '' : attribute,
                              });

                              return attributes;
                            }, []) || []
                          }
                          className="cp_co_form-rc-select"
                          classNamePrefix="cp_co_form-rc-select"
                          onChange={(option) => setFieldValue(`attributes[${attribute?.objectKey}]`, option?.value)}
                          placeholder="-"
                          menuPortalTarget={document.body}
                          value={
                            values?.attributes?.[attribute?.objectKey]
                              ? {
                                  label: values?.attributes?.[attribute?.objectKey],
                                  value: values?.attributes?.[attribute?.objectKey],
                                }
                              : { label: '-', value: '' }
                          }
                        />
                      ) : attribute?.inputType === 'shortText' ? (
                        <Field name={`attributes[${attribute?.objectKey}]`}>
                          {({ field }) => (
                            <div>
                              <input
                                className="cp_co_form-input-element"
                                type="text"
                                placeholder="Type your..."
                                {...field}
                              />
                            </div>
                          )}
                        </Field>
                      ) : attribute?.inputType === 'longText' ? (
                        <Field name={`attributes[${attribute?.objectKey}]`}>
                          {({ field }) => (
                            <div>
                              <textarea
                                className="cp_co_form-textarea-element"
                                type="textarea"
                                placeholder="Type your..."
                                {...field}
                              />
                            </div>
                          )}
                        </Field>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ))}
            </div>

            <div className="cp_co_form-button-wrapper">
              <button
                disabled={isConfirmLoading}
                onClick={() => handleConfirmFormStep()}
                className="cp_co_form-button-confirm"
                type="button"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

CompanyObjectForm.defaultProps = {
  formTitle: 'VALIDATE EXTRACTED DATA',
  attributes: PropTypes.any,
};

CompanyObjectForm.propTypes = {
  formTitle: PropTypes.any,
  attributes: PropTypes.any,
};

export default CompanyObjectForm;
