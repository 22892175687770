/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

export const SignInValidation = Yup.object()
  .shape({
    email: Yup.string().email().min(2, 'Too Short!').max(50, 'Too Long!').required('Please enter email'),
    password: Yup.string().required('Please enter password'),
  })
  .defined();

export const SignInInitialValues = {
  email: '',
  password: '',
};
