/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_COMPANY_PROFILE, routes } from 'constant';
import { getCompanyProfile } from 'api/companyProfile';
import { catchBlock } from 'utils';

import 'components/CompanyProfileDropdown/style.css';

const CompanyProfileDropdown = ({ wrapperClass }) => {
  const navigate = useNavigate();

  const [companyProfileData, setCompanyProfileData] = useState([]);
  const [companyProfile, setCompanyProfile] = useState(null);

  const { mutate: getCompanyProfileApi, isFetching: isLoading } = useQuery({
    queryKey: ['get-company-profile'],
    queryFn: getCompanyProfile,
    refetchOnMount: 'always',
    onSuccess: (response) => {
      const optionsData = (response?.data || []).map((company) => {
        return { value: company._id, label: company.name };
      });

      if (optionsData.length > 0)
        setCompanyProfileData([
          ...optionsData,
          {
            value: null,
            label: 'New Company',
          },
        ]);
      else setCompanyProfileData(optionsData);

      const defaultCompanyProfile = localStorage.getItem(DEFAULT_COMPANY_PROFILE)
        ? JSON.parse(localStorage.getItem(DEFAULT_COMPANY_PROFILE))
        : null;

      if (
        defaultCompanyProfile &&
        optionsData.some((companyProfile) => companyProfile.value === defaultCompanyProfile.value)
      ) {
        setCompanyProfile(defaultCompanyProfile);
      } else {
        updateDefaultCompanyProfile(optionsData.length > 0 ? optionsData[0] : null);
      }
    },
    onError: (err) => {
      catchBlock(err);
    },
  });

  const updateDefaultCompanyProfile = (companyProfile) => {
    if (companyProfile.value !== null) {
      setCompanyProfile(companyProfile);
      localStorage.setItem(DEFAULT_COMPANY_PROFILE, companyProfile ? JSON.stringify(companyProfile) : null);
    } else {
      navigate(routes.companyProfile);
    }
  };

  useEffect(() => {
    if (getCompanyProfileApi) getCompanyProfileApi();
  }, [getCompanyProfileApi]);

  const customOption = (props) => {
    return (
      <components.Option {...props}>
        <div className={`cpd-rc-select-option ${!props.value ? 'create' : ''}`}>
          {/* {props.value ? (
            <img src="/assets/images/text-logo.png" alt="" />
          ) : (
            <img src="/assets/images/add-orange-new.png" alt="" />
          )} */}
          {!props.value && <img src="/assets/images/add-orange-new.png" alt="" />}
          {props.label}
        </div>
      </components.Option>
    );
  };

  const customSingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div className="cpd-rc-select-selected-option">
          {/* <img src="/assets/images/text-logo.png" alt="" /> */}
          {props.children}
        </div>
      </components.SingleValue>
    );
  };

  return (
    <>
      {companyProfileData && companyProfileData?.length > 0 && (
        <div className={`cpd_containers ${wrapperClass}`}>
          <Select
            isSearchable={false}
            options={companyProfileData}
            value={companyProfile || null}
            components={{ Option: customOption, SingleValue: customSingleValue }}
            className="cpd-rc-select"
            classNamePrefix="cpd-rc-select"
            onChange={(option) => updateDefaultCompanyProfile(option || null)}
            menuPortalTarget={document.body}
          />
        </div>
      )}
    </>
  );
};

CompanyProfileDropdown.defaultProps = {
  wrapperClass: '',
};

CompanyProfileDropdown.propTypes = {
  wrapperClass: PropTypes.string,
};

export default CompanyProfileDropdown;
