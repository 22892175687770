import React from 'react';
import RevolutioniseMarketing from './components/RevolutioniseMarketing';

import './style.css';

const MarketingHome = () => {
  return (
    <div className="width_89 justify-content-center m-auto ">
      <RevolutioniseMarketing />
    </div>
  );
};

export default MarketingHome;
