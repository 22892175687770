/* eslint-disable import/order */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaFacebookF } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useGoogleLogin } from '@react-oauth/google';
import { useMutation } from '@tanstack/react-query';
import {
  ACCESS_TOKEN_EXPIRATION_TIME_LOCAL_STORAGE_KEY,
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  ACCESS_TOKEN_LOGIN_EXPIRE_MINUTES_LOCAL_STORAGE_KEY,
  ACCESS_TOKEN_LOGIN_TIME_LOCAL_STORAGE_KEY,
  COMPANY_PROFILE_REDIRECT_URL_KEY,
  FREE_WORKSPACE_URL_LOCAL_STORAGE_KEY,
  routes,
  SUB_ID_LOCAL_STORAGE_KEY,
  TOAST_CONFIG,
  USER_DATA_LOCAL_STORAGE_KEY,
} from 'constant';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Button, FormHeading, InputBox, TextTitleLogo, TitleLogo } from 'components';
import PageTitle from 'components/PageTitle';
import { googleLoginApi, login, socialLogin } from 'api/auth';
import { getCompanyProfile } from 'api/companyProfile';
import { addSubscription, fetchAllSubscriptions } from 'api/payment';
import { catchBlock } from 'utils';
import Google from '../../../assets/google-image.png';
import { SignInInitialValues, SignInValidation } from './constant';

import './style.css';

const LoginPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [initVals, setInitVals] = useState(SignInInitialValues);
  useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search);
      setInitVals({ ...initVals, ...{ email: query.get('email') } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  const { mutate: getAllSubscriptions } = useMutation(fetchAllSubscriptions);
  const addSubscriptionMutation = useMutation(addSubscription);

  const { mutate: loginPostMutate, isLoading } = useMutation(login, {
    onSuccess: async (data) => {
      toast.success(data.message, TOAST_CONFIG);
      const sid = localStorage.getItem(SUB_ID_LOCAL_STORAGE_KEY);
      const userObj = {
        name: `${data.data.user.first_name} ${data.data.user.last_name}`,
        email: data.data.user.email,
        is_free_user: data.data.user.is_free_user,
        is_sub_user: data.data.user.is_sub_user,
      };
      if (sid) {
        await addSubscriptionMutation.mutate(
          { sid },
          {
            onSuccess: (subData) => {
              userObj.is_sub_user = subData.is_sub_user;
              localStorage.removeItem(SUB_ID_LOCAL_STORAGE_KEY);
            },
            onError: (error) => {
              catchBlock(error);
              localStorage.removeItem(SUB_ID_LOCAL_STORAGE_KEY);
            },
          },
        );
      }
      localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, data.data.token);
      localStorage.setItem(USER_DATA_LOCAL_STORAGE_KEY, JSON.stringify(userObj));
      localStorage.setItem(ACCESS_TOKEN_LOGIN_TIME_LOCAL_STORAGE_KEY, moment());
      localStorage.setItem(
        ACCESS_TOKEN_EXPIRATION_TIME_LOCAL_STORAGE_KEY,
        moment().add(ACCESS_TOKEN_LOGIN_EXPIRE_MINUTES_LOCAL_STORAGE_KEY, 'minutes'),
      );
      await handleRedirectAfterLogin();
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const { mutate: socialLoginPostMutate, isLoading: socialIsLoading } = useMutation(socialLogin, {
    onSuccess: async (data) => {
      toast.success(data.message, TOAST_CONFIG);
      const sid = localStorage.getItem(SUB_ID_LOCAL_STORAGE_KEY);
      const userObj = {
        name: `${data.data.user.first_name} ${data.data.user.last_name}`,
        email: data.data.user.email,
        is_free_user: data.data.user.is_free_user,
        is_sub_user: data.data.user.is_sub_user,
      };
      if (sid) {
        await addSubscriptionMutation.mutate(
          { sid },
          {
            onSuccess: (subData) => {
              userObj.is_sub_user = subData.is_sub_user;
              localStorage.removeItem(SUB_ID_LOCAL_STORAGE_KEY);
            },
            onError: (error) => {
              catchBlock(error);
              localStorage.removeItem(SUB_ID_LOCAL_STORAGE_KEY);
            },
          },
        );
      }
      localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, data.data.token);
      localStorage.setItem(USER_DATA_LOCAL_STORAGE_KEY, JSON.stringify(userObj));
      localStorage.setItem(ACCESS_TOKEN_LOGIN_TIME_LOCAL_STORAGE_KEY, moment());
      localStorage.setItem(
        ACCESS_TOKEN_EXPIRATION_TIME_LOCAL_STORAGE_KEY,
        moment().add(ACCESS_TOKEN_LOGIN_EXPIRE_MINUTES_LOCAL_STORAGE_KEY, 'minutes'),
      );
      await handleRedirectAfterLogin();
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const { mutate: socialGoogleLoginPostMutate, isLoading: socialGoogleIsLoading } = useMutation(socialLogin, {
    onSuccess: async (data) => {
      toast.success(data.message, TOAST_CONFIG);
      const sid = localStorage.getItem(SUB_ID_LOCAL_STORAGE_KEY);
      const userObj = {
        name: `${data.data.user.first_name} ${data.data.user.last_name}`,
        email: data.data.user.email,
        is_free_user: data.data.user.is_free_user,
        is_sub_user: data.data.user.is_sub_user,
      };
      if (sid) {
        await addSubscriptionMutation.mutate(
          { sid },
          {
            onSuccess: (subData) => {
              userObj.is_sub_user = subData.is_sub_user;
              localStorage.removeItem(SUB_ID_LOCAL_STORAGE_KEY);
            },
            onError: (error) => {
              catchBlock(error);
              localStorage.removeItem(SUB_ID_LOCAL_STORAGE_KEY);
            },
          },
        );
      }
      localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, data.data.token);
      localStorage.setItem(USER_DATA_LOCAL_STORAGE_KEY, JSON.stringify(userObj));
      localStorage.setItem(ACCESS_TOKEN_LOGIN_TIME_LOCAL_STORAGE_KEY, moment());
      localStorage.setItem(
        ACCESS_TOKEN_EXPIRATION_TIME_LOCAL_STORAGE_KEY,
        moment().add(ACCESS_TOKEN_LOGIN_EXPIRE_MINUTES_LOCAL_STORAGE_KEY, 'minutes'),
      );
      await handleRedirectAfterLogin();
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const Register = () => {
    navigate({ pathname: routes.register, search });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const headers = {
        Authorization: `Bearer ${codeResponse.access_token}`,
        Accept: 'application/json',
      };
      googleLoginApi(headers, codeResponse.access_token)
        .then((res) => {
          const requestValues = {
            email: res.email,
            first_name: res.given_name,
            last_name: res.family_name,
            social_login_type: 'Google',
            provider_id: res.id,
          };
          socialGoogleLoginPostMutate(requestValues);
        })
        .catch((err) => catchBlock(err));
    },
    onError: (error) => catchBlock(error),
  });

  // Login with facebook
  // const [data, setData] = useState(
  //   localStorage.getItem('facebook_user_deails') ? JSON.parse(localStorage.getItem('facebook_user_deails')) : {},
  // );
  // const [picture, setPicture] = useState(
  //   localStorage.getItem('facebook_user_deails')
  //     ? JSON.parse(localStorage.getItem('facebook_user_deails'))?.profile_picture
  //     : '',
  // );

  const LoginPost = useCallback(
    (values) => {
      const requestValues = {
        email: values.email,
        password: values.password,
      };
      loginPostMutate(requestValues);
    },
    [loginPostMutate],
  );

  const responseFailFacebook = (error) => {
    catchBlock(error);
  };

  const responseFacebook = (response) => {
    if (response.status === 'loginCancelled') {
      return false;
    }
    if (response.error) {
      catchBlock(response);
    }
    if (response.name) {
      const splitname = response.name.split(' ');
      const firstName = splitname[0] || '';
      const lastName = splitname[1] || '';
      const requestValues = {
        email: response.email,
        first_name: firstName,
        last_name: lastName,
        social_login_type: 'Facebook',
        provider_id: response.id,
      };
      socialLoginPostMutate(requestValues);
    }
    return true;
  };

  const handleRedirectAfterLogin = async () => {
    const workspaceUrl = localStorage.getItem(FREE_WORKSPACE_URL_LOCAL_STORAGE_KEY);

    if (workspaceUrl) localStorage.removeItem(FREE_WORKSPACE_URL_LOCAL_STORAGE_KEY);

    if (!workspaceUrl || workspaceUrl === undefined) {
      await getCompanyProfile()
        .then((response) => {
          if ((response?.data || []).length <= 0) {
            localStorage.setItem(COMPANY_PROFILE_REDIRECT_URL_KEY, routes.dashBoard);

            setTimeout(() => {
              navigate(routes.companyProfile);
            }, 0);
          } else navigate(routes.dashBoard);
        })
        .catch((error) => catchBlock(error));
    } else navigate(workspaceUrl);
  };

  return (
    <>
      <PageTitle title="Login" />

      <div className="login_page_height">
        <div className="login_logo_div_manage">
          <TextTitleLogo />
        </div>
        <div className="login_padding_bottom login_background">
          <div className="justify-content-center align-items-center m-auto my-5 pt-2 text-center login_width_45">
            <FormHeading>Welcome back</FormHeading>
            <p className="login_form_paragraph">Your brainstorming space is waiting for you</p>

            <div className="pb-4 login_fields_div pe-4 ps-4">
              <Formik
                initialValues={initVals}
                enableReinitialize
                validationSchema={SignInValidation}
                onSubmit={LoginPost}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Field
                      placeholder="Email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      component={InputBox}
                      customClass="input-focus-class"
                      disabled={initVals.email !== ''}
                    />
                    {errors.email && touched.email ? (
                      <div className="d-block justify-content-center mx-auto text-start login_lightRed">
                        {errors.email}
                      </div>
                    ) : null}
                    <Field
                      placeholder="Password"
                      name="password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      component={InputBox}
                      customClass="input-focus-class"
                    />
                    {errors.password && touched.password ? (
                      <div className="d-block justify-content-center mx-auto text-start login_lightRed">
                        {errors.password}
                      </div>
                    ) : null}

                    <Button disabled={isLoading}>{isLoading ? 'Loading...' : 'Log in'}</Button>
                    <button
                      type="button"
                      disabled={socialGoogleIsLoading}
                      onClick={() => googleLogin()}
                      className="submitButtonwithGoogle"
                    >
                      <img src={Google} alt="" style={{ width: '2rem' }} />
                      {socialGoogleIsLoading ? 'Loading...' : 'Login with Google'}
                    </button>
                    <button type="button" disabled={socialIsLoading} className="facebookBackgroundButton">
                      <FaFacebookF style={{ color: 'white' }} />
                      {socialIsLoading ? (
                        <h6 className="login_facebook_loader">Loading...</h6>
                      ) : (
                        <FacebookLogin
                          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                          autoLoad={false}
                          fields="name,email,picture"
                          onSuccess={responseFacebook}
                          onFail={responseFailFacebook}
                          onProfileSuccess={responseFacebook}
                          size="metro"
                          tag="button"
                          className="faceBookButton"
                        />
                      )}
                    </button>
                    <div className="login_buttonbox">
                      <button type="submit" className="registerBtn" onClick={Register}>
                        Register
                      </button>
                      <Link className="forgotbtn" to={routes.forgotPassword}>
                        Forgotten password ?
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
