import React from 'react';
import { Field, Form, Formik } from 'formik';
import { InputBox } from 'components';
import { ContactInitialValues, ContactValidation } from './constannt';

import './style.css';

const ContactForm = () => {
  return (
    <div className="responsive_contact_content">
      <h1 className="contct_heading">Contact Us</h1>
      <p className="text-white contact_page_form_main font_contact_Weight500">Explore the future with us.</p>
      <p className="text-white contact_page_form_main margin_contact_10 font_contact_Weight500">
        Feel free to get in touch.
      </p>
      <Formik initialValues={ContactInitialValues} validationSchema={ContactValidation}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <p className="contact_form_label">Name</p>
            <Field type="text" component={InputBox} placeholder="Full Name" name="name" value={values.name} />
            <p className="contact_form_label">Email</p>
            <Field
              type="text"
              component={InputBox}
              placeholder="Email Address"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <p className="contact_form_label">Message</p>
            <Field
              type="text"
              component={InputBox}
              placeholder="How can we get better ? "
              value={values.message}
              name="message"
            />
            {errors.email && touched.email ? (
              <div className=" d-block  justify-content-center mx-auto text-start Error_msg">{errors.email}</div>
            ) : null}
            <button type="submit" className="contact_button contact_submitButton mb-5" disabled={isSubmitting}>
              Send Message
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
