import React, { useRef } from 'react';
import {
  CollaborativeAIBrainstorming,
  IntroducingaNewWayOfIdeation,
  TailoredExperienceForYourNeeds,
  WhatClientSay,
} from './Components';

import './style.css';

const TechHome = () => {
  const resultRef = useRef();

  return (
    <div className="width_89 justify-content-center m-auto ">
      <div className="container">
        <CollaborativeAIBrainstorming resultRef={resultRef} />

        <IntroducingaNewWayOfIdeation />

        <TailoredExperienceForYourNeeds />
      </div>
    </div>
  );
};

export default TechHome;
