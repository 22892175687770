/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
// eslint-disable-next-line import/order
import { TOAST_CONFIG } from 'constant';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { InputBox } from 'components';
import { sendEnquiry } from 'api/auth';
import { ModalInitialValues, ModalValidation } from './constant';

import './style.css';

const ModalComponent = ({ onClose }) => {
  const [showMsg, setShowMsg] = useState(false);

  const { mutate: sendEnquiryMutate, isLoading } = useMutation(sendEnquiry, {
    onSuccess: (data) => {
      toast.success(data.message, TOAST_CONFIG);
      // if (formRef.current) {
      //   formRef.current.resetForm();
      // }

      setShowMsg(true);

      setTimeout(() => {
        onClose();
      }, 3000);
    },
    onError: (error) => {
      toast.error('Something went wrong, please try again later.', TOAST_CONFIG);
      catchBlock(error);
      setTimeout(() => {
        onClose();
      }, 3000);
    },
  });
  const handleSubmitModal = (values) => {
    console.log('Modal initial values', values);
    const valueObj = {
      email: values.email,
    };
    sendEnquiryMutate(valueObj);
  };
  return (
    <Modal show size="md" centered onHide={onClose}>
      <Modal.Body className="modal-body">
        <h4 className="modalTitle">- LET US KNOW WHERE TO CONTACT YOU</h4>
        <h4 className="modalTitleTwo">
          We will initiate the <br /> communication.
        </h4>

        <Formik initialValues={ModalInitialValues} validationSchema={ModalValidation} onSubmit={handleSubmitModal}>
          {({ values, errors, touched, handleSubmit, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <div className="input-btn-div">
                <div className="form-input-div">
                  <Field
                    placeholder="Email"
                    name="email"
                    customClass="extraclass"
                    onChange={handleChange}
                    component={InputBox}
                    type="email"
                    value={values?.email}
                  />
                  {errors.email && touched.email ? (
                    <div
                      className="lightRed d-block d-absolute justify-content-center text-start"
                      style={{ position: 'absolute' }}
                    >
                      {errors.email}
                    </div>
                  ) : null}
                </div>

                <button type="submit" className="button">
                  Send
                </button>
              </div>
            </Form>
          )}
        </Formik>

        {showMsg && <h4 className="modal-submit-msg">Thank you for subscribing!</h4>}
      </Modal.Body>
    </Modal>
  );
};

ModalComponent.defaultProps = {
  onClose: PropTypes.func,
};

ModalComponent.propTypes = {
  onClose: PropTypes.func,
};
export default ModalComponent;
