import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const PageTitle = ({ title }) => {
  const defaultTitle = 'Ideon | AI Strategising';
  return (
    <div>
      {title && (
        <Helmet>
          <title>{title || defaultTitle}</title>
        </Helmet>
      )}
    </div>
  );
};

PageTitle.defaultProps = {
  title: PropTypes.string,
};

PageTitle.propTypes = {
  title: PropTypes.string,
};
export default PageTitle;
