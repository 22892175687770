import React from 'react';
import NewEmployeeAmico from 'assets/New-employee-amico.png';

import './style.css';

const AboutUs = () => {
  return (
    <div>
      <h1 className="baner_about_heading">About us</h1>
      <div className="width_about_60 justify-content-center m-auto text-center">
        <img src={NewEmployeeAmico} alt="NewEmployeeAmico" className="mb-5 width_about_image" />
      </div>
      <div className="width_about_70 justify-content-center m-auto text-class">
        <p>
          We provide tailored collaborative AI brainstorming solutions designed to help businesses generate innovative
          and creative ideas. Our tool is perfect for companies that are looking to unlock their team's full potential
          and tap into new perspectives.
        </p>
        <p>
          With our platform, businesses can say goodbye to the traditional, stale brainstorming sessions that often
          produce the same ideas over and over again. Our tool uses the power of AI to help teams think outside the box
          and come up with fresh ideas based on their unique perspectives.
        </p>
        <p>
          Our tool is designed to encourage collaboration and communication among team members, whether they are working
          remotely or in the office. Our user-friendly interface requires no technical expertise and is easy to use,
          making it accessible to everyone in the team.
        </p>
        <p>
          The AI-assisted brainstorming process provides a helpful set of features to explore thinking in 3 different
          ways:
        </p>
        <p>
          <ul>
            <li>Ideas - sets new streams of thought processes</li>
            <li>Information - gives details and background of the topic</li>
            <li>Examples - provides insightful implementations</li>
          </ul>
        </p>
        <p>
          They guide team members through the ideation process. The platform is intuitive, flexible, and customizable to
          fit your team's needs.
        </p>
        <p>
          Our collaborative AI brainstorming tool is the future of ideation, helping businesses to innovate and stay
          ahead of the competition. With our platform, your team can unlock their creativity and generate new ideas that
          will drive growth and success. Experience the power of AI-assisted innovation and try our tool today.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
