import React from 'react';
import MindMapEdit from 'fetures/MindMap/edit';
import PageTitle from 'components/PageTitle';

const EditMindMapPage = () => {
  return (
    <>
      <PageTitle title="Mind-Map" />

      <MindMapEdit />
    </>
  );
};

export default EditMindMapPage;
