import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN_LOCAL_STORAGE_KEY, USER_DATA_LOCAL_STORAGE_KEY } from 'constant';
import { routes } from 'constant/routes';

import './style.css';

const Display = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem(USER_DATA_LOCAL_STORAGE_KEY));

  return (
    <div className="dropdownmanage margin_dropdown">
      <NavDropdown title={userData?.name || ''} id="collasible-nav-dropdown" className="selectBox dropleft">
        <NavDropdown.Item
          className="dropDownItem "
          onClick={() => {
            navigate(routes.dashBoard);
          }}
        >
          Dashboard
        </NavDropdown.Item>

        <NavDropdown.Item
          className="navbar-select-box-item"
          onClick={() => {
            navigate(routes.myAccount);
          }}
        >
          My Account
        </NavDropdown.Item>

        <NavDropdown.Item
          className="dropDownItem"
          onClick={() => {
            localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
            navigate(routes.login);
          }}
        >
          Logout
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
};

export default Display;
