import React, { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import { routes, TOAST_CONFIG } from 'constant';
// eslint-disable-next-line import/order
import { Field, Form, Formik } from 'formik';
// import { useNavigate } from 'react-router-dom';
import { Button, InputBox, TextTitleLogo, TitleLogo } from 'components';
import PageTitle from 'components/PageTitle';
import { checkResetPasswordToken, resetPasswordPost } from 'api/auth';
import { catchBlock } from 'utils';
import { initialValues, validationSchema } from './constant';

import './style.css';

const ResetPasswordPage = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isTokenExpire, setIsTokenExpire] = useState(null);
  const { mutate: resetPasswordPostMutate, isLoading } = useMutation(resetPasswordPost, {
    onSuccess: (data) => {
      toast.success(data.message, TOAST_CONFIG);
      navigate(routes.login);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  useQuery({
    queryKey: ['check-forgot-token', searchParams.get('forgotten_password_code')],
    queryFn: checkResetPasswordToken,
    enabled: !!searchParams.get('forgotten_password_code'),
    onSuccess: (data) => {
      if (data.data.valid === true) {
        setIsTokenExpire(false);
      } else {
        setIsTokenExpire(true);
      }
    },
    onError: (error) => {
      setIsTokenExpire(true);
      catchBlock(error);
    },
  });

  const resetPasswordSubmitHandler = (values) => {
    const valueObj = {
      new_password: values.password,
      forgotten_password_code: searchParams.get('forgotten_password_code'),
    };
    resetPasswordPostMutate(valueObj);
  };

  return (
    <>
      <PageTitle title="Reset Password" />

      <div className="`reset_background` page-height">
        <div className="reset_logo_div">
          <TextTitleLogo />
        </div>

        <div className="reset_password_width justify-content-center align-items-center mx-auto text-center my-5 pt-2">
          <h1 className="reset_password_title">Reset Password</h1>
          <div className="pb-4 ps-4 pe-4 formik-div login_fields_div">
            {isTokenExpire === false && (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={resetPasswordSubmitHandler}
                innerRef={formRef}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Field
                      placeholder="Password"
                      name="password"
                      type="password"
                      onChange={handleChange}
                      value={values.password}
                      onBlur={handleBlur}
                      component={InputBox}
                    />
                    {errors.password && touched.password ? (
                      <div className="reset-password-lightRed d-block justify-content-start mx-auto text-start">
                        {errors.password}
                      </div>
                    ) : null}
                    <Field
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      onChange={handleChange}
                      value={values.confirmPassword}
                      onBlur={handleBlur}
                      component={InputBox}
                    />
                    {errors.confirmPassword && touched.confirmPassword ? (
                      <div className="reset-password-lightRed d-block justify-content-start mx-auto text-start">
                        {errors.confirmPassword}
                      </div>
                    ) : null}
                    <Button disabled={isLoading}>{isLoading ? 'Loading...' : 'Reset Password'}</Button>
                  </Form>
                )}
              </Formik>
            )}
            {isTokenExpire === true && <h5 className="expire-title-class">Your link has been expire</h5>}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordPage;
