/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { routes, SUB_ID_LOCAL_STORAGE_KEY } from 'constant';
import { addSubscription, fetchAllSubscriptions } from 'api/payment';
// import "./styles.css";

const ActivateSubscription = () => {
  const navigate = useNavigate();
  const addSubscriptionMutation = useMutation(addSubscription);
  const { mutate: fetchUserSub, isFetching: isFetchingUserSubscription } = useQuery({
    queryFn: fetchAllSubscriptions,
    onSuccess: async (data) => {
      if (data.qty > 0) {
        navigate(routes.dashBoard, { replace: true });
      } else {
        const sid = localStorage.getItem(SUB_ID_LOCAL_STORAGE_KEY);
        await addSubscriptionMutation.mutate(
          { sid },
          {
            onSuccess: (subData) => {
              localStorage.removeItem(SUB_ID_LOCAL_STORAGE_KEY);
              navigate(routes.dashBoard, { replace: true });
            },
            onError: (error) => {
              catchBlock(error);
              localStorage.removeItem(SUB_ID_LOCAL_STORAGE_KEY);
              navigate(routes.dashBoard, { replace: true });
            },
          },
        );
      }
    },
    onError: (err) => {
      catchBlock(err);
    },
  });

  useEffect(() => {
    if (fetchUserSub) fetchUserSub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="spinner-div">
      <Spinner animation="border" className="spiner" />
    </div>
  );
};

export default ActivateSubscription;
