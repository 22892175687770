import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'constant';
import { ModalComponent } from 'components';
// eslint-disable-next-line import/order
import FounderStory from '../FounderStory';
// eslint-disable-next-line import/order
import WhatClientSay from '../WhatClientSay';
import heroVid from 'assets/data_not_colors.mp4';
import EasilyShareable from 'assets/easily_shareable.mp4';
import heroSec from 'assets/hero-sec.png';
import KnowYourNextStep from 'assets/know_next_step.mp4';
import startingBusiness from 'assets/Starting-business.png';
import backgroundVedio from 'assets/tailored_context.mp4';
import tailerContVid from 'assets/tailored_context_v3.mp4';

import './style.css';

const TailoredExperienceForYourNeeds = () => {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();

  const modalOpen = () => {
    navigate(routes.register);
    // window.location.href = 'https://app.lemcal.com/@ideon/demo';
    // setModalShow(true);
  };

  const modalClose = () => {
    setModalShow(false);
  };
  return (
    <>
      <div className="md_section_wrapper">
        <div className="md_section_wrapper_info_section">
          <div className="md_align_text md_section_wrapper_info_section_one">
            <h2 className="md_business_text revolution_title">Businesses value data, not colors</h2>
            <div>
              <p className="md_p_details text-white text_btns_div_p mb-0 md_section_wrapper_info_section_one_text">
                Ideon is not your next whiteboard. Colors, drawing tools and emojis are not decision makers. Don’t spend
                time on them.
              </p>
            </div>
            <div>
              <h2 className="md_trust-text child_titles_text">
                <FaCheck className="checked_svg" /> Focus on what’s important - data and information
              </h2>
            </div>
            {modalShow === true && <ModalComponent onClose={modalClose} />}
          </div>
          <div className="md_section_wrapper_info_section_two">
            <div className="md_hero_banner_img">
              {/* <img src={heroSec} alt="homrBanner" className="w-100 responsive_collaborative_image" /> */}
              <video
                autoPlay
                loop
                muted
                playsInline
                className="w-full w-100 responsive_collaborative_video responsive_collaborative_image"
              >
                <source src={heroVid} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        <div className="md_section_wrapper_info_section_reverse">
          <div className="md_section_wrapper_info_section_reverse_one md_align_text">
            <h2 className="md_business_text md_tailored_text revolution_title">Tailored with your context in mind</h2>
            <div>
              <p className="md_p_details text-white text_btns_div_p mb-0 md_section_wrapper_info_section_one_text">
                The AI models are providing contextualised results based on your website, products, services, values and
                more.
              </p>
            </div>
            <div>
              <h2 className="md_trust-text child_titles_text">
                <FaCheck className="checked_svg" /> Explore tailored solutions and ideas
              </h2>
            </div>
            {modalShow === true && <ModalComponent onClose={modalClose} />}
          </div>
          <div className="md_section_wrapper_info_section_reverse_two">
            <div className="md_hero_banner_img">
              {/* <img src={heroSec} alt="homrBanner" className="w-100 responsive_collaborative_image" /> */}
              <video
                autoPlay
                loop
                muted
                playsInline
                className="w-full w-100 responsive_collaborative_video responsive_collaborative_image"
              >
                <source src={tailerContVid} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        <div className="md_section_wrapper_info_section">
          <div className="md_section_wrapper_info_section_one md_align_text">
            <h2 className="md_business_text md_know_text revolution_title">Know your next step</h2>
            <div>
              <p className="md_p_details text-white text_btns_div_p mb-0 md_section_wrapper_info_section_one_text">
                Ideon shows the path to the solution. No need to be confused and overwhelmed.
              </p>
            </div>
            <div>
              <h2 className="md_trust-text child_titles_text">
                <FaCheck className="checked_svg" /> Genrate actionable steps to accomplish a solution
              </h2>
            </div>
            {modalShow === true && <ModalComponent onClose={modalClose} />}
          </div>
          <div className="md_section_wrapper_info_section_two">
            <div className="md_hero_banner_img">
              {/* <img src={heroSec} alt="homrBanner" className="w-100 responsive_collaborative_image" /> */}
              <video
                autoPlay
                loop
                muted
                playsInline
                className="w-full w-100 responsive_collaborative_video responsive_collaborative_image"
              >
                <source src={KnowYourNextStep} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        <div className="md_section_wrapper_info_section_reverse">
          <div className="md_section_wrapper_info_section_reverse_one md_align_text">
            <h2 className="md_business_text md_easily_text revolution_title">Easily shareable</h2>
            <div>
              <p className="md_p_details text-white text_btns_div_p mb-0 md_section_wrapper_info_section_one_text">
                Select your prefered solutions and Ideon creates an automatic business report with all the information
                and action steps you need.
              </p>
            </div>
            <div>
              <h2 className="md_trust-text child_titles_text">
                <FaCheck className="checked_svg" /> Export your strategy to an insightful PDF report
              </h2>
            </div>
            {modalShow === true && <ModalComponent onClose={modalClose} />}
          </div>
          <div className="md_section_wrapper_info_section_reverse_two">
            <div className="md_hero_banner_img">
              {/* <img src={heroSec} alt="homrBanner" className="w-100 responsive_collaborative_image" /> */}
              <video
                autoPlay
                loop
                muted
                playsInline
                className="w-full w-100 responsive_collaborative_video responsive_collaborative_image"
              >
                <source src={EasilyShareable} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        <div className="md_section_wrapper_info_section">
          <div className="md_section_wrapper_info_section_one md_align_text">
            <h2 className="md_business_text md_science_text revolution_title">Science behind it</h2>
            <div>
              <p className="md_p_details text-white text_btns_div_p mb-0 md_section_wrapper_info_section_one_text">
                Ideon’s User Experience is deisgned based on a scientific study, showing 50% creativity boost and up to
                30% productivity surge.
              </p>
            </div>
            <div>
              <h2 className="md_trust-text child_titles_text">
                <FaCheck className="checked_svg" />
                Eliminate bias and promote diverse thinking
              </h2>
            </div>
            {modalShow === true && <ModalComponent onClose={modalClose} />}
          </div>
          {/* <div className="md_section_wrapper_info_section_two">
            <div className="md_hero_banner_img">
              <img src={heroSec} alt="homrBanner" className="w-100 responsive_collaborative_image" />
            </div>
          </div> */}
        </div>
      </div>
      {/* <WhatClientSay /> */}
      <FounderStory />
      <div className="md_getstartted_wrapper">
        <div className="row md_number_wrapper">
          <h2 className="md_get-title mb-0">Get started with Ideon</h2>
          <div className="col-lg-8 col-xl-8 col-sm-12">
            <div>
              <p className="text-white text_btns_div_p mb-0 md_turn_text md_section_wrapper_info_section_one_text">
                Turn your next challenge into an opportunity.
              </p>
            </div>
            <div className="md_text_btns">
              <div className="btns_flex_div">
                {/* <span> */}
                <Link to={routes.register} className="trial_btn">
                  Start free trial
                </Link>
                {/* </span>
            <span> */}
                <Link to="https://app.lemcal.com/@ideon/demo" className="book_btn">
                  REQUEST A DEMO
                </Link>
                {/* </span> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-sm-12">
            <ul className="md_list">
              <li>
                <FaCheck className="checked_svg" />
                Explore tailored solutions
              </li>
              <li>
                <FaCheck className="checked_svg" /> Generate action steps{' '}
              </li>
              <li>
                <FaCheck className="checked_svg" /> Download strategy report{' '}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TailoredExperienceForYourNeeds;
