import React from 'react';
import MyAccount from 'fetures/MyAccount';
import PageTitle from 'components/PageTitle';

const MyAccountPage = () => {
  return (
    <>
      <PageTitle title="My Account" />

      <MyAccount />
    </>
  );
};

export default MyAccountPage;
