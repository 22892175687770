import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import AppLogout from 'AppLogout';
import { ACCESS_TOKEN_LOCAL_STORAGE_KEY, routes, SUB_ID_LOCAL_STORAGE_KEY } from 'constant';
// eslint-disable-next-line import/order
import PropTypes from 'prop-types';

const PrivateRoute = ({ Component }) => {
  const params = useParams();
  const location = useLocation();
  if (location.pathname.indexOf('/subscription') === 0) {
    localStorage.setItem(SUB_ID_LOCAL_STORAGE_KEY, params?.sub_id);
  }

  const loginUser = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  let isLogin = false;
  if (loginUser !== null) {
    isLogin = true;
  }
  return isLogin ? (
    <AppLogout>
      <Component />
    </AppLogout>
  ) : (
    <Navigate to={{ pathname: routes.login, search: location?.search }} />
  );
};

PrivateRoute.propTypes = {
  Component: PropTypes.any.isRequired,
};

export default PrivateRoute;
