import React, { useState } from 'react';
import { ModalComponent } from 'components';
import ProjectionsPana from 'assets/Projections-pana.png';

import './style.css';

const GetStartedWithIdeonToday = () => {
  const [modalShow, setModalShow] = useState(false);

  const modalOpen = () => {
    window.location.href = 'https://app.lemcal.com/@ideon/demo';
    // setModalShow(true);
  };

  const modalClose = () => {
    setModalShow(false);
  };

  return (
    <div className="white_getstarted_shadow_warper white_shadow_warper margin_getstarted_manager">
      <div className="d-flex  white_getstarted_shadow_warper white_getstarted_warper margin_5 peta_getStarted_width justify-content-center m-auto flex-column flex-md-row ">
        <div className="width_getstarted_50 responsive_getstarted_width">
          <h1 className="sub_getstarted_title_drak ">Get started with Ideon today</h1>
          <div className="mb-3">
            <span className="d-block">Empower your employees.</span>
            <span>Start boosting your business today.</span>
          </div>
          <button type="submit" className="baner_getstarted_btn" onClick={modalOpen}>
            Request Free Demo
          </button>
          {modalShow === true && <ModalComponent onClose={modalClose} />}
        </div>

        <div className="width_getstarted_40 responsive_getstarted_Image">
          <img src={ProjectionsPana} alt="ProjectionsPana" className="w-100 ms-4" />
        </div>
      </div>
    </div>
  );
};

export default GetStartedWithIdeonToday;
