import * as Yup from 'yup';

export const MindMampValidation = Yup.object().shape({
  prompt: Yup.string().required('Please enter  prompt'),
  is_customize_workspace: Yup.boolean(),
  related_areas: Yup.array().of(
    Yup.object().shape({
      area: Yup.string()
        .test('is_customize_workspace', 'Please enter area.', (values, context) => {
          if (context?.options?.context?.is_customize_workspace === true && values === undefined) {
            return false;
          }
          return true;
        })
        .nullable(true),
    }),
  ),
  description: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter correct url!',
  ),
  more_details: Yup.string().test('is_customize_workspace', 'Please enter details.', (values, context) => {
    if (context?.parent?.is_customize_workspace === true && values === undefined) {
      return false;
    }
    return true;
  }),
});

export const MindMampInitialValues = {
  prompt: '',
  more_details: '',
  is_customize_workspace: false,
  description: '',
  related_areas: [
    {
      area: '',
    },
  ],
};

export const BrainstormingAreaIntialValues = {
  website: '',
};

export const BrainstormingAreaValidation = Yup.object().shape({
  website: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!',
    )
    .required('Please enter website'),
});
