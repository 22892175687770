/* eslint-disable react/no-array-index-key */
/* eslint-disable simple-import-sort/imports */
import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import Swal from 'sweetalert2';
import { routes } from 'constant';
import animationLink from 'assets/link.json';
import animationTrash from 'assets/trash.json';
import userIconImg from 'assets/users.png';

import 'fetures/MyAccount/Components/Companies/style.css';
import CollaboratorModal from '../CollaboratorModal';

const Companies = ({
  companyProfileData,
  deleteCompanyProfileApi,
  isLoadingDeleteCompanyProfile,
  updateCompanyProfileData,
}) => {
  const navigate = useNavigate();

  const [deleteActionId, setDeleteActionId] = useState(null);
  const [collaboratorActionId, setCollaboratorActionId] = useState(null);

  const handleDeleteCompany = (id) => {
    setDeleteActionId(id);

    setTimeout(() => {
      setDeleteActionId(null);
    }, 950);

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCompanyProfileApi(id);
      }
    });
  };

  const handleOpenCollaboratorModal = (id) => {
    setCollaboratorActionId(id);
  };

  const handleCloseCollaboratorModal = (count) => {
    updateCompanyProfileData(collaboratorActionId, { collaboratorCount: count });
    setCollaboratorActionId(null);
  };

  const handleCreateNewCompany = () => {
    navigate(routes.companyProfile);
  };

  return (
    <div className="myaccount_companies-container">
      <h2 className="myaccount_companies-title">Companies</h2>

      <div className="myaccount_companies-card-wrapper">
        <div className="myaccount_companies-card create-card">
          <h4 className="myaccount_companies-card-title">New Company</h4>

          <div className="myaccount_companies-card-icon">
            <AiOutlinePlus onClick={handleCreateNewCompany} color="#fff" size="60px" fontWeight="bold" />
          </div>
        </div>

        {companyProfileData.map((company, index) => (
          <div className="myaccount_companies-card" key={index}>
            <div className="myaccount_companies-card-head">
              <h5>Company Name</h5>
              <h4>{company.name || ''}</h4>
            </div>

            <div className="myaccount_companies-card-footer">
              <div className="myaccount_companies-card-collaborator">
                <img src={userIconImg} alt="" />

                <p>{company.collaboratorCount ? `${company.collaboratorCount + 1} Users` : 'Only Me'}</p>
              </div>

              {company.isOwner === true && (
                <div className="myaccount_companies-card-action">
                  <button
                    type="button"
                    className="collaborator-btn"
                    onClick={() => handleOpenCollaboratorModal(company._id)}
                  >
                    <Lottie
                      options={{
                        loop: false,
                        autoplay: false,
                        animationData: animationLink,
                      }}
                      height={25}
                      width={25}
                      isStopped={collaboratorActionId !== company._id}
                    />
                  </button>

                  <button
                    type="button"
                    disabled={isLoadingDeleteCompanyProfile}
                    className="delete-btn"
                    onClick={() => handleDeleteCompany(company._id)}
                  >
                    {isLoadingDeleteCompanyProfile ? (
                      'Loading...'
                    ) : (
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: false,
                          animationData: animationTrash,
                        }}
                        height={25}
                        width={25}
                        isStopped={deleteActionId !== company._id}
                      />
                    )}
                  </button>

                  <Link className="edit-btn" to={routes.companyProfileEdit.replace(':id', company._id)}>
                    Edit
                  </Link>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {collaboratorActionId !== null && (
        <CollaboratorModal
          companyProfileId={collaboratorActionId}
          handleCloseCollaboratorModal={handleCloseCollaboratorModal}
        />
      )}
    </div>
  );
};

Companies.defaultProps = {
  companyProfileData: [],
  deleteCompanyProfileApi: PropTypes.func,
  isLoadingDeleteCompanyProfile: false,
  updateCompanyProfileData: PropTypes.func,
};

Companies.propTypes = {
  companyProfileData: PropTypes.array,
  deleteCompanyProfileApi: PropTypes.func,
  isLoadingDeleteCompanyProfile: PropTypes.bool,
  updateCompanyProfileData: PropTypes.func,
};

export default Companies;
