import React from 'react';
import CompanyProfileEdit from 'fetures/CompanyProfile/edit';
import PageTitle from 'components/PageTitle';

const CompanyProfileEditPage = () => {
  return (
    <>
      <PageTitle title="Edit Company Profile" />
      <CompanyProfileEdit />
    </>
  );
};

export default CompanyProfileEditPage;
