import React, { useState } from 'react';
import HoldingTheArrowAmico from 'assets/Holding-the-arrow-amico.png';

import './style.css';

const SuccessIsMission = () => {
  return (
    <div className="success_section">
      <div className="success_container text-white">
        <div className="success_img_container">
          <img src={HoldingTheArrowAmico} alt="HoldingTheArrowAmico" className="success_img_container_img" />
        </div>

        <div className="success_detail_container">
          <h1 className="success_detail_container_h1">Your success is our mission!</h1>
        </div>
      </div>
    </div>
  );
};

export default SuccessIsMission;
