import * as Yup from 'yup';

export const FormValidation = Yup.object().shape({
  email: Yup.string().required('Please enter email address.').email('Please enter valid email address.'),
  days: Yup.number().max(180, 'Maximum trial days is 180'),
});

export const FormValues = {
  email: '',
  days: '',
};
