import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { RiMenu5Fill } from 'react-icons/ri';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { InputBox } from 'components';

import './style.css';

const DetailModal = ({ handleClose }) => {
  return (
    <div className="modal-container">
      <div className="modal-heading">
        <h6 className="modal-heading-title">Detail</h6>
        <button type="button" onClick={handleClose} className="modal-heading-btn">
          <RiMenu5Fill color="#f05436" size="22" fontWeight="bold" />
        </button>
      </div>

      <div className="modal-body-form-container">
        <p className="modal-body-title">Comments</p>
        <p className="modal-body-p">
          <span className="modal-body-span">Email : </span>navaz@gmail.com
        </p>
        <p className="modal-body-p">
          <span className="modal-body-span">Email : </span>navaz@gmail.com
        </p>
        <p className="modal-body-p">
          <span className="modal-body-span">Email : </span>navaz@gmail.com
        </p>
        <p className="modal-body-p">
          <span className="modal-body-span">Email : </span>navaz@gmail.com
        </p>
        <p className="modal-body-p">
          <span className="modal-body-span">Email : </span>navaz@gmail.com
        </p>

        <div className="modal-body-form">
          <div className="modal-body-formik-form">
            <Formik initialValues="" validationSchema="">
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="modal-form">
                    <div className="modal-input-div">
                      <Field
                        placeholder="Comment..."
                        name="comment"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.comment}
                        component={InputBox}
                        customClass="modal-input-style"
                        type="text"
                      />
                      {errors.comment && touched.comment ? (
                        <div className="lightRed d-block  justify-content-center mx-auto text-start">
                          {errors.comment}
                        </div>
                      ) : null}
                    </div>

                    <button type="button" className="modal-form-btn">
                      Send
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <div className="modal-body-attachment-container">
        <p className="modal-body-title">Attachments</p>

        <div className="modal-attachment-grid-div">
          <div className="modal-attachment-btn-div">
            <AiOutlinePlus color="#fff" size="23" fontWeight="bold" className="modal-attachment-plus-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

DetailModal.defaultProps = {
  handleClose: PropTypes.func,
};

DetailModal.propTypes = {
  handleClose: PropTypes.func,
};
export default DetailModal;
