// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice } from '@reduxjs/toolkit';

const initialState = { nodes: [] };

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    updateWorkspaceNodes: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.nodes = [...action.payload];
    },
  },
});

export const { updateWorkspaceNodes } = workspaceSlice.actions;

export default workspaceSlice.reducer;
