// eslint-disable-next-line simple-import-sort/imports
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { Field, Form, Formik } from 'formik';
import { TOAST_CONFIG } from 'constant';
import { InputBox } from 'components';
import { catchBlock } from 'utils';
import { addSubscriptionDays } from 'api/payment';
import { FormValidation, FormValues } from './constant';

import './style.css';

const AddSubscriptionDayComponent = () => {
  const formRef = useRef();

  const { mutate: postReferralLink, isLoading } = useMutation(addSubscriptionDays, {
    onSuccess: (res) => {
      toast.success(res.message, TOAST_CONFIG);
      if (formRef.current) {
        formRef.current.resetForm();
      }
    },
    onError: (error) => {
      catchBlock(error);
      toast.error('Invalid Data', TOAST_CONFIG);
    },
  });

  const handleSubmitFun = (values) => {
    postReferralLink(values);
  };

  return (
    <div className="generate-link-page">
      <div className="generate-link-page-container">
        <Formik
          initialValues={FormValues}
          validationSchema={FormValidation}
          onSubmit={handleSubmitFun}
          innerRef={formRef}
        >
          {({ values, errors, touched, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div className="mt-3">
                <Field
                  type="text"
                  customClass="generate-link-page-container-inputs-div-text-input"
                  component={InputBox}
                  placeholder="Enter Email"
                  name="email"
                  value={values.email}
                />
                {errors.email && touched.email ? (
                  <div className="d-bloc text-start Error_msg_text">{errors.email}</div>
                ) : null}
              </div>

              <div className="mt-3">
                <Field
                  type="number"
                  customClass="generate-link-page-container-inputs-div-text-input"
                  component={InputBox}
                  placeholder="Enter Days"
                  name="days"
                  value={values.days}
                />
                {errors.days && touched.days ? (
                  <div className="d-block mb-1 text-start Error_msg_text">{errors.days}</div>
                ) : null}
              </div>

              <div className="mt-3">
                <div className="generate-link-page-container-inputs-div-buttons justify-content-center">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="generate-link-page-container-inputs-div-string-generate"
                  >
                    Update Subscription
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddSubscriptionDayComponent;
