import Api from './axios.config';

export const referralLinkApi = async (data) => {
  const response = await Api.post('/referral/generate', data);
  return response.data;
};

export const referralCodeExisting = async (code) => {
  const response = await Api.get(`/referral/is_existing/${code}`);
  return response.data;
};
