/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'constant';
import gojs from '../../../../assets/gojscards.png';

import './style.css';

const RevolutioniseMarketing = () => {
  const cards = [
    'Unleash the Power of Unified Brainpower',
    'Think Outside the Box',
    'Future-proof your business',
    'Stay Ahead in the Market',
    'Enhance Collaboration',
    'Turn Innovative Ideas into Reality',
    'Save Time and Reduce Stress',
    'Drive Results',
    'Strategise with Creativity',
  ];

  const marketingCards = [
    {
      title: 'Conduct competitive analysis',
      para: "Provide a visual representation of competitors' strategies and tactics, helping the marketing team identify gaps and opportunities in the market.",
    },
    {
      title: 'Do content ideation',
      para: 'Generate content ideas based on trending topics, industry news, and keyword research, aligning content with audience interests.',
    },
    {
      title: 'Construct persona development',
      para: 'Aid in the creation and visualization of buyer personas, making it easier for teams to tailor their marketing efforts to specific customer segments.',
    },
    {
      title: 'Optimise marketing funnels',
      para: 'Visualize the entire marketing funnel and identify areas for improvement, from lead generation to conversion and retention strategies.',
    },
    {
      title: 'Increase campaigns reach and quality',
      para: 'Aid in the creation and visualization of buyer personas, making it easier for teams to tailor their marketing efforts to specific customer segments.',
    },
    {
      title: 'Develop campaigns evaluation methods',
      para: 'Visualize the entire marketing funnel and identify areas for improvement, from lead generation to conversion and retention strategies.',
    },
  ];

  const solutionCards = [
    {
      title: 'Immersive, focused and distraction-free environment',
      detail: `  Ideon's purpose-built platform is designed specifically for brainstorming, with a clean and simple interface that eliminates distractions. Our immersive UI environment fosters creative thinking and sets us apart from conventional "whiteboard" alternatives.`,
    },
    {
      title: 'Eliminating individual bias',
      detail:
        " Ideon's AI-powered features help users to overcome their own biases and generate more diverse and creative ideas.Ideon's AI-powered features help users to overcome their own biases and generate more diverse and creative ideas.",
    },
    {
      title: `Understanding user's context`,
      detail:
        "Ideon's AI takes the user's context, values, needs, and limitations into account, providing more personalized and relevant assistance.",
    },
    {
      title: 'Enabling easy sharing of results',
      detail: `
      Ideon makes it easy to share brainstorming results with others through export capabilities.`,
    },
    {
      title: 'Assisting with mental roadblocks',
      detail: `Ideon's AI content generation capabilitie helps users to overcome mental roadblocks and expand their perspectives.`,
    },
    {
      title: 'Prioritizing quality over quantity',
      detail: ` Ideon is designed to help users generate high-quality ideas, rather than simply a large number of ideas. This is achieved through a combination of AI-powered features and a focus on simplicity.`,
    },
    {
      title: 'Providing a user-friendly interface',
      detail: `Ideon's user-friendly interface requires little to no learning curve, making it accessible to users of all skill levels.`,
    },
  ];

  return (
    <div>
      <div className="revolution_title_width my-5 responsive_collaborative_content ">
        <h2 className="revolution_title">Revolutionise Marketing</h2>
        <div className="mt-3 mb-4 child_titles">
          <h2 className="child_titles_text">with</h2>
          <h2 className="child_titles_text">AI-Powered Brainstorming</h2>
        </div>
      </div>

      <div className="text_btns_div">
        <p className="text-white text_btns_div_p">Join the hundreds of teams already witnessing skyrocketing results</p>
        <div className="btns_flex_div">
          <Link to={routes.login} className="trial_btn">
            Start free trial
          </Link>
          <Link to="https://app.lemcal.com/@ideon/demo" className="book_btn">
            REQUEST A DEMO
          </Link>
        </div>
      </div>

      <div className="cards_btn_div">
        {cards.map((item, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="card_div" key={index}>
              <div className="circle_cards">
                <h5 className="card_texts" data-content={item}>
                  {item}
                </h5>
              </div>
            </div>
          );
        })}
      </div>

      <div className="marketings">
        <p className="text-white text_btns_div_p">How you can use Ideon for Marketing</p>
        <div className="marketing_cards">
          {marketingCards.map((item, index) => {
            return (
              <div className="marketing_card" key={index}>
                <div className="card_img_div">
                  <img className="card_img" src={gojs} alt="card.img" />
                </div>

                <div className="details_div" key={index}>
                  <h4 className="details_div_title">{item.title}</h4>
                  <p className="details_div_para">{item.para}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="more_btn_div">
          <button type="button" className="more_btn">
            See more..
          </button>
        </div>
      </div>

      <div className="solution_div">
        <p className="text-white text_btns_div_p">Why our solution is the best one</p>
        <div className="solitions_cards">
          {solutionCards.map((item, index) => {
            return (
              <div className={index % 2 == 0 ? 'solitions_card_left' : 'solitions_card_right'} key={index}>
                <div className="solitions_btn_div">
                  <p className="solitions_btn_div_p">{item.title}</p>
                </div>
                <div className="solitions_details_div">
                  <p className="solitions_details_div_para">{item.detail}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RevolutioniseMarketing;
