/* eslint-disable prettier/prettier */
import React from 'react';
import founder1 from 'assets/tech/story1.jpg';
import founder2 from 'assets/tech/story2.jpg';

import './style.css';

const FounderStory = () => {
  const test = [];
  return (
    <div className="md_section_founder_story">
      <h2 className="md_business_text md_know_text revolution_title">Founders story</h2>
      <div className="md_section_wrapper_info_section_reverse">
        <div className="md_section_wrapper_info_section_reverse_one md_align_text">
          <div>
            <p className="md_p_details text-white text_btns_div_p mb-0 md_section_wrapper_info_section_one_text">
              As design thinkers frustrated by ineffective brainstorming, we knew there had to be a better way to
              innovate. That's when we had the spark to enhance human creativity with AI-powered visual thinking.
              <br />
              <br />
              In 2023, my co-founder and I launched Ideon to pioneer a new approach - combining the best of human
              ingenuity and machine intelligence. We developed a 5-step framework to help companies visually solve
              problems. Today, Ideon equips businesses worldwide to systematically innovate, make strategic decisions,
              and overcome complex challenges.
            </p>
          </div>
        </div>
        <div className="md_section_wrapper_info_section_reverse_two">
          <div className="md_hero_banner_img">
            <img src={founder1} alt="homrBanner" className="w-100 " />
          </div>
        </div>
      </div>
      <div className="md_section_wrapper_info_section">
        <div className="md_align_text md_section_wrapper_info_section_one">
          <div>
            <p className="md_p_details text-white text_btns_div_p mb-0 md_section_wrapper_info_section_one_text">
              Ideon was born from the drive to empower visionary thinking. As pioneers in this space, we are committed
              to pushing the boundaries of innovation and transforming businesses through the power of AI-enhanced
              visual thinking.
              <br />
              <br />
              The future belongs to those who can visualize it first. Let's envision it together.
            </p>
          </div>
        </div>
        <div className="md_section_wrapper_info_section_two">
          <div className="md_hero_banner_img">
            <img src={founder2} alt="homrBanner" className="w-100 " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FounderStory;
