/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
// import "./styles.css";

const TestPage = () => {
  // state variables
  const [nodeDataArray, setNodeDataArray] = useState([
    { key: 1, text: 'Alpha', color: 'lightblue' },
    { key: 2, text: 'Beta', color: 'orange' },
    { key: 3, text: 'Gamma', color: 'lightgreen' },
    { key: 4, text: 'Delta', color: 'pink' },
  ]);
  const [linkDataArray, setLinkDataArray] = useState([
    { key: -1, from: 1, to: 2 },
    { key: -2, from: 1, to: 3 },
    { key: -3, from: 2, to: 2 },
    { key: -4, from: 3, to: 4 },
    { key: -5, from: 4, to: 1 },
  ]);
  const [skipsDiagramUpdate, setSkipsDiagramUpdate] = useState(false);

  const mapData = {
    class: 'TreeModel',
    nodeDataArray: [
      { key: 1, icon: 'home.svg', main: 'food mall' },
      {
        main: 'Farm to Table Food Mall',
        information:
          'A food mall that offers a range of fresh and locally sourced produce, allowing customers to enjoy farm-to-table meals. This concept promotes healthy eating, supports local farmers, and emphasizes sustainability.',
        icon: 'idea.svg',
        parent: 1,
        key: 2,
      },
      {
        main: 'Gourmet Food Court',
        information:
          'An upscale food mall that features a variety of gourmet food options, including international cuisines and artisanal dishes. This concept focuses on providing high-quality, nutritious meals made with premium ingredients, appealing to food lovers seeking more sophisticated dining options.',
        icon: 'idea.svg',
        parent: 1,
        key: 3,
      },
      {
        main: 'Health and Wellness Food Bazaar',
        information:
          'A food mall dedicated to health and wellness, offering a selection of organic, plant-based, and allergen-friendly food options. Alongside food stalls, it can also feature wellness-related services like nutrition consultations, cooking classes, and fitness workshops, providing a holistic approach to healthy living.',
        icon: 'idea.svg',
        parent: 1,
        key: 4,
      },
      {
        main: 'Farm-to-Table Delights',
        information:
          'Bring together local farmers and producers to showcase their fresh, organic produce, dairy products, meats, and more. Customers can sample and purchase a variety of nutritious options, while also learning about the importance of sustainable farming practices.',
        icon: 'idea.svg',
        parent: 4,
        key: 5,
      },
      {
        main: 'Wellness Workshops and Cooking Demos',
        information:
          'Offer a series of workshops and cooking demonstrations led by nutritionists, chefs, and wellness experts. These sessions can cover topics like healthy meal planning, mindful eating, and simple yet delicious recipes using seasonal ingredients. Participants can gain valuable knowledge and practical tips to incorporate into their daily lives.',
        icon: 'idea.svg',
        parent: 4,
        key: 6,
      },
      {
        main: 'Fitness Food Fusion',
        information:
          'Partner with fitness studios and gyms to create a food bazaar that caters specifically to individuals with active lifestyles. Showcase a variety of protein-rich foods, superfood smoothies, and power-packed snacks. Additionally, offer fitness classes and mini sessions where participants can try out various exercise programs suited to their needs and interests.',
        icon: 'idea.svg',
        parent: 4,
        key: 7,
      },
      {
        main: 'Athletic Avocado Toast',
        information:
          'A twist on traditional avocado toast, this fitness food fusion combines mashed avocado with slices of lean turkey or chicken, topped with fresh tomatoes and sprinkled with chia seeds for an added boost of nutrition.',
        icon: 'idea.svg',
        parent: 7,
        key: 8,
      },
      {
        main: 'Quinoa Power Bowl',
        information:
          "This fitness food fusion bowl features a base of protein-rich quinoa topped with grilled chicken, roasted sweet potatoes, saut\u00e9ed spinach, and a drizzle of light lemon dressing. It's a nutrient-packed meal that will fuel your fitness regimen.",
        icon: 'idea.svg',
        parent: 7,
        key: 9,
      },
      {
        main: 'Protein Pancake Wrap',
        information:
          'Combine the goodness of protein-packed pancakes with the convenience of a wrap. Make protein pancakes using ingredients like oat flour and Greek yogurt, then fill them with banana slices, almond butter, and a sprinkle of cinnamon. Roll it up and enjoy as a post-workout snack or quick breakfast on the go.',
        icon: 'idea.svg',
        parent: 7,
        key: 10,
      },
      {
        main: 'Interactive Wellness Workshops',
        information:
          'Host interactive workshops where participants can learn and practice different wellness techniques, such as yoga, meditation, and stress management. Provide expert facilitators and create a nurturing environment for attendees to unwind and rejuvenate.',
        icon: 'idea.svg',
        parent: 6,
        key: 11,
      },
      {
        main: 'Healthy Cooking Demos',
        information:
          'Organize cooking demonstrations that showcase healthy and delicious recipes using fresh ingredients. Invite professional chefs or nutritionists to guide the audience through each step of the cooking process. Include tips on meal planning, nutrition, and ways to adapt recipes for dietary restrictions.',
        icon: 'idea.svg',
        parent: 6,
        key: 12,
      },
      {
        main: 'Nutritional Education Sessions',
        information:
          'Offer educational sessions led by nutritionists or dieticians to educate participants about the importance of nutrition and healthy eating habits. Cover topics such as food labels, portion control, and meal balancing. Provide resources and handouts for attendees to take home with them.',
        icon: 'idea.svg',
        parent: 6,
        key: 13,
      },
    ],
  };

  // maps for faster state modification
  const mapNodeKeyIdx = new Map();
  const mapLinkKeyIdx = new Map();
  refreshNodeIndex(nodeDataArray);
  refreshLinkIndex(linkDataArray);

  function refreshNodeIndex(nodeArr) {
    mapNodeKeyIdx.clear();
    nodeArr.forEach((n, idx) => {
      mapNodeKeyIdx.set(n.key, idx);
    });
  }

  function refreshLinkIndex(linkArr) {
    mapLinkKeyIdx.clear();
    linkArr.forEach((l, idx) => {
      mapLinkKeyIdx.set(l.key, idx);
    });
  }

  function handleModelChange(obj) {
    if (obj === null) return;
    const { insertedNodeKeys } = obj;
    const { modifiedNodeData } = obj;
    const { removedNodeKeys } = obj;
    const { insertedLinkKeys } = obj;
    const { modifiedLinkData } = obj;
    const { removedLinkKeys } = obj;

    // copy data to new array, but maintain references
    let nodeArr = nodeDataArray.slice();
    let linkArr = linkDataArray.slice();
    // maintain maps of modified data so insertions don't need slow lookups
    const modifiedNodeMap = new Map();
    const modifiedLinkMap = new Map();
    // only update state if we've actually made a change
    let arrChanged = false;

    // handle node changes
    if (modifiedNodeData) {
      modifiedNodeData.forEach((nd) => {
        modifiedNodeMap.set(nd.key, nd);
        const idx = mapNodeKeyIdx.get(nd.key);
        if (idx !== undefined && idx >= 0) {
          nodeArr.splice(idx, 1, nd);
          arrChanged = true;
        }
      });
    }
    if (insertedNodeKeys) {
      insertedNodeKeys.forEach((key) => {
        const nd = modifiedNodeMap.get(key);
        const idx = mapNodeKeyIdx.get(key);
        if (nd && idx === undefined) {
          mapNodeKeyIdx.set(nd.key, nodeArr.length);
          nodeArr.push(nd);
          arrChanged = true;
        }
      });
    }
    if (removedNodeKeys) {
      nodeArr = nodeArr.filter((nd) => {
        if (removedNodeKeys.includes(nd.key)) {
          arrChanged = true;
          return false;
        }
        return true;
      });
      refreshNodeIndex(nodeArr);
    }
    // handle link changes
    if (modifiedLinkData) {
      modifiedLinkData.forEach((ld) => {
        modifiedLinkMap.set(ld.key, ld);
        const idx = mapLinkKeyIdx.get(ld.key);
        if (idx !== undefined && idx >= 0) {
          linkArr.splice(idx, 1, ld);
          arrChanged = true;
        }
      });
    }
    if (insertedLinkKeys) {
      insertedLinkKeys.forEach((key) => {
        const ld = modifiedLinkMap.get(key);
        const idx = mapLinkKeyIdx.get(key);
        if (ld && idx === undefined) {
          mapLinkKeyIdx.set(ld.key, linkArr.length);
          linkArr.push(ld);
          arrChanged = true;
        }
      });
    }
    if (removedLinkKeys) {
      linkArr = linkArr.filter((ld) => {
        if (removedLinkKeys.includes(ld.key)) {
          arrChanged = true;
          return false;
        }
        return true;
      });
      refreshLinkIndex(linkArr);
    }

    if (arrChanged) {
      setNodeDataArray(nodeArr);
      setLinkDataArray(linkArr);
      setSkipsDiagramUpdate(true);
    }
  }

  function initDiagram() {
    const $ = go.GraphObject.make;
    const diagram = $(go.Diagram, {
      'commandHandler.zoomFactor': 1.1,
      initialDocumentSpot: go.Spot.Top,
      padding: new go.Margin(100, 10, 10, 10),
      initialViewportSpot: go.Spot.Top,
      initialContentAlignment: go.Spot.Top,
      allowCopy: false,
      'draggingTool.dragsTree': true,
      'draggingTool.dragsLink': true,
      allowDelete: true,
      // initialAutoScale: go.Diagram.Uniform,
      maxSelectionCount: 1, // users can select only one part at a time
      validCycle: go.Diagram.CycleDestinationTree, // make sure users can only create trees
      // 'clickCreatingTool.insertPart': function (loc) {
      //   // method override must be function, not =>
      //   const node = go.ClickCreatingTool.prototype.insertPart.call(this, loc);
      //   // if (node !== null) {
      //   //   this.diagram.select(node);
      //   //   this.diagram.commandHandler.scrollToPart(node);
      //   //   this.diagram.commandHandler.editTextBlock(node.findObject('NAMETB'));
      //   // }
      //   return node;
      // },
      layout: $(go.LayeredDigraphLayout, { direction: 90, alignOption: go.LayeredDigraphLayout.AlignAll }),
      // layout: $(go.TreeLayout, {
      //   treeStyle: go.TreeLayout.StyleLastParents,
      //   arrangement: go.TreeLayout.ArrangementHorizontal,
      //   // allowZoom: "true",
      //   // properties for most of the tree:
      //   angle: 90,
      //   layerSpacing: 35,
      //   // properties for the "last parents":
      //   alternateAngle: 90,
      //   alternateLayerSpacing: 35,
      //   alternateAlignment: go.TreeLayout.AlignmentBus,
      //   alternateNodeSpacing: 20,
      //   alternatePortSpot: new go.Spot(0.001, 1, 20, 0),
      //   alternateChildPortSpot: go.Spot.Right,
      // }),
      'undoManager.isEnabled': true, // enable undo & redo
    });

    // This is the actual HTML context menu:
    var cxElement = document.getElementById('contextMenu');

    // an HTMLInfo object is needed to invoke the code to set up the HTML cxElement
    var myContextMenu = $(go.HTMLInfo, {
      show: showContextMenu,
      hide: hideContextMenu,
    });
    // the template for each attribute in a node's array of item data (Examples)
    var itemTempl = $(
      go.Panel,
      'TableRow',
      $(go.Shape, 'Circle', {
        column: 1,
        desiredSize: new go.Size(9, 9),
        strokeJoin: 'round',
        strokeWidth: 2.5,
        stroke: '#F05436',
        fill: '#F05436',
        name: 'point',
        margin: new go.Margin(0, 10, 10, 0),
      }),
      $(
        go.TextBlock,
        textStyle(),
        {
          spacingAbove: 1.5,
          column: 2,
          minSize: new go.Size(240, NaN),
          maxSize: new go.Size(240, NaN),
          margin: new go.Margin(0, 10, 10, 0),
          stroke: '#fff',
          name: 'item',
          font: '9pt poppins, Helvetica, Arial, sans-serif',
          editable: false, // by default newlines are allowed
        },
        new go.Binding('text', '').makeTwoWay(),
      ),
    );

    diagram.nodeTemplate = $(
      go.Node,
      'Spot',
      {
        contextMenu: myContextMenu,
        selectionObjectName: 'BODY',
        isShadowed: true,
        shadowOffset: new go.Point(0, 0),
        shadowColor: 'white',
        shadowBlur: 13,
        // dragsTree: true,
        // mouseEnter: (e, node) => node.findObject("BUTTON").opacity = node.findObject("BUTTONX").opacity = 1,
        // mouseLeave: (e, node) => node.findObject("BUTTON").opacity = node.findObject("BUTTONX").opacity = 0,
        // handle dragging a Node onto a Node to (maybe) change the reporting relationship
        mouseDragEnter: (e, node, prev) => {
          const { diagram } = node;
          const selnode = diagram.selection.first();
          if (!mayWorkFor(selnode, node)) return;
          const shape = node.findObject('SHAPE');
          if (shape) {
            shape._prevFill = shape.fill; // remember the original brush
            shape.fill = 'darkred';
          }
        },
        mouseDragLeave: (e, node, next) => {
          const shape = node.findObject('SHAPE');
          if (shape && shape._prevFill) {
            shape.fill = shape._prevFill; // restore the original brush
          }
        },
        mouseDrop: (e, node) => {
          const { diagram } = node;
          const selnode = diagram.selection.first(); // assume just one Node in selection
          if (mayWorkFor(selnode, node)) {
            // find any existing link into the selected node
            const link = selnode.findTreeParentLink();
            if (link !== null) {
              // reconnect any existing link
              link.fromNode = node;
            } else {
              // else create a new link
              diagram.toolManager.linkingTool.insertLink(node, node.port, selnode, selnode.port);
            }
            save(true);
          }
        },
      },

      {
        selectionAdornmentTemplate: $(
          go.Adornment,
          'Auto',
          $(go.Shape, 'RoundedRectangle', {
            fill: null,
            strokeWidth: 0,
            parameter1: 15,
            stroke: '#F05436',
          }),
          $(go.Placeholder),
        ), // end Adornment
      },
      // for sorting, have the Node.text be the data.name
      new go.Binding('text', 'name'),
      // bind the Part.layerName to control the Node's layer depending on whether it isSelected
      new go.Binding('layerName', 'isSelected', (sel) => (sel ? 'Foreground' : '')).ofObject(),
      $(
        go.Panel,
        'Auto',
        {
          shadowVisible: true,
          name: 'BODY',
          margin: new go.Margin(40, 5, 0, 5),
        },
        // define the node's outer shape
        $(
          go.Shape,
          'RoundedRectangle',
          {
            name: 'SHAPE',
            parameter1: 15,
            fill: '#09091D',
            // stroke: 'white',
            strokeWidth: 2,
            stretch: go.GraphObject.Fill,
            portId: '',
          },
          new go.Binding('opacity', 'isHighlighted', (h) => (h ? '0.1' : '1')).ofObject(),
        ),
        $(
          go.Panel,
          'Vertical',
          new go.Binding('opacity', 'isHighlighted', (h) => (h ? '0.1' : '1')).ofObject(),
          $(
            go.Panel,
            'Spot',
            {
              click: (e, node) => {
                var { diagram } = node.part;
                diagram.startTransaction('highlight');
                diagram.clearHighlighteds();
                handleCommentModel(node);
                diagram.commitTransaction('highlight');
              },
              cursor: 'pointer',
              alignment: go.Spot.Right,
              margin: new go.Margin(3, 8, 0, 0),
            },
            $(go.Picture, {
              desiredSize: new go.Size(27, 27),
              margin: 1.5,
              // cursor: "pointer",
              source: '/assets/images/example.svg', // the default image
              // click: (e, button) => addExample(button.part)
            }),
          ),

          $(
            go.Panel,
            'Table',
            {
              minSize: new go.Size(260, NaN),
              maxSize: new go.Size(260, NaN),
              margin: new go.Margin(10, 13, 0, 13),
              defaultAlignment: go.Spot.Left,
            },
            $(go.RowColumnDefinition, { column: 2, width: 4 }),

            $(
              go.Panel,
              'Vertical',
              {
                minSize: new go.Size(NaN, 152),
                maxSize: new go.Size(NaN, 152),
                name: 'textPanel',
              },
              $(
                go.TextBlock,
                textStyle(), // the main title
                {
                  name: 'NAMETB',
                  row: 0,
                  column: 0,
                  columnSpan: 2,
                  font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                  wrap: go.TextBlock.WrapFit,
                  editable: false,
                  minSize: new go.Size(260, NaN),
                  maxSize: new go.Size(260, NaN),
                  textAlign: 'center',
                  stroke: '#F05436',
                  overflow: go.TextBlock.OverflowEllipsis,
                },
                new go.Binding('text', 'main', function (main) {
                  return main.toUpperCase();
                }).makeTwoWay(),
              ),

              $(
                go.TextBlock,
                textStyle(), // the body
                {
                  spacingBelow: 1.5,
                  visible: true,
                  name: 'body',
                  row: 2,
                  column: 0,
                  columnSpan: 2,
                  font: '9pt poppins, Helvetica, Arial, sans-serif',
                  editable: false, // by default newlines are allowed
                  minSize: new go.Size(260, NaN),
                  maxSize: new go.Size(260, NaN),
                  margin: new go.Margin(12, 0, 0, 0),
                  overflow: go.TextBlock.OverflowEllipsis,
                },
                new go.Binding('text', 'body', function (body) {
                  return body;
                }).makeTwoWay(),
              ),

              $(
                go.TextBlock,
                textStyle(), // the information
                {
                  spacingBelow: 1.5,
                  visible: true,
                  name: 'information',
                  row: 2,
                  column: 0,
                  columnSpan: 2,
                  font: '9pt poppins, Helvetica, Arial, sans-serif',
                  editable: false, // by default newlines are allowed
                  minSize: new go.Size(260, NaN),
                  maxSize: new go.Size(260, NaN),
                  margin: new go.Margin(12, 0, 0, 0),
                  overflow: go.TextBlock.OverflowEllipsis,
                },
                new go.Binding('text', 'information').makeTwoWay(),
              ),
            ),

            $(
              go.Panel,
              'Table',
              {
                visible: false,
                name: 'examples',
                row: 2,
                margin: new go.Margin(14, 0, 0, 0),
                itemTemplate: itemTempl,
              },
              new go.Binding('itemArray', 'examples'),
            ),

            $(
              go.Panel,
              'Horizontal',
              {
                visible: false,
                row: 3,
                name: 'add',
                cursor: 'pointer',
                margin: new go.Margin(15, 0, 0, 0),
                // alignment: go.Spot.Center,
                // click: (e, button) => addItem(button.part)
              },
              $(go.Shape, 'PlusLine', {
                width: 7,
                height: 7,
                stroke: '#fff',
                margin: new go.Margin(0, 3, 1.9, 0),
                strokeWidth: 1.5,
              }),
              $(go.TextBlock, 'Add Another Example', {
                font: '8pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#fff',
              }),
            ),

            $(
              go.Panel,
              'Horizontal',
              {
                height: 45,
                stretch: go.GraphObject.Fill,
                row: 4,
                column: 0,
                columnSpan: 2,
                margin: new go.Margin(16, 0, 0, 0),
              },
              $(
                go.Panel,
                'Spot',
                {
                  click: (e, button) => generate(button.part),
                  cursor: 'pointer',
                  name: 'generate',
                  margin: new go.Margin(0, 5, 5, 0),
                },
                $(go.Shape, 'RoundedRectangle', {
                  parameter1: 11,
                  width: 78,
                  height: 35,
                  strokeWidth: 0,
                  fill: '#F05436',
                }),
                $(go.TextBlock, 'Generate', {
                  name: 'idea',
                  font: 'bold 8pt poppins, Helvetica, Arial, sans-serif',
                  stroke: '#fff',
                }),
              ),

              $(
                go.Panel,
                'Horizontal',
                {
                  visible: false,
                  margin: new go.Margin(0, 0, 0, 0),
                  name: 'BtnPanel',
                },
                $(
                  go.Panel,
                  'Spot', // generate idea
                  {
                    opacity: 0,
                    click: (e, button) => loadPythonScript('my_prompt_value', 'IDEA', button.part),
                    cursor: 'pointer',
                    name: 'ideaBtn',
                  },
                  $(go.Shape, 'RoundedRectangle', {
                    parameter1: 11,
                    width: 35,
                    height: 35,
                    strokeWidth: 0,
                    margin: new go.Margin(0, 5, 5, 0),
                    fill: '#F05436',
                  }),
                  $(go.Picture, {
                    name: 'Info',
                    desiredSize: new go.Size(22, 22),
                    margin: 1.5,
                    // cursor: "pointer",
                    source: '/assets/images/idea-white.svg', // the default image
                    // click: (e, button) => addInfo(button.part)
                  }),
                ),
                $(
                  go.Panel,
                  'Spot', // generate info
                  {
                    opacity: 0,
                    click: (e, button) => loadPythonScript('my_prompt_value', 'INFO', button.part),
                    cursor: 'pointer',
                    name: 'infoBtn',
                  },
                  $(go.Shape, 'RoundedRectangle', {
                    parameter1: 11,
                    width: 35,
                    height: 35,
                    strokeWidth: 0,
                    margin: new go.Margin(0, 5, 5, 0),
                    fill: '#F05436',
                  }),
                  $(go.Picture, {
                    name: 'Info',
                    desiredSize: new go.Size(24, 24),
                    margin: 1.5,
                    source: '/assets/images/question-white.svg', // the default image
                    // click: (e, button) => addInfo(button.part)
                  }),
                ),
                $(
                  go.Panel,
                  'Spot', // generate example
                  {
                    opacity: 0,
                    click: (e, button) => loadPythonScript('my_prompt_value', 'EXAMPLE', button.part),
                    cursor: 'pointer',
                    name: 'exampleBtn',
                  },
                  $(go.Shape, 'RoundedRectangle', {
                    parameter1: 11,
                    width: 35,
                    height: 35,
                    strokeWidth: 0,
                    margin: new go.Margin(0, 5, 5, 0),
                    fill: '#F05436',
                  }),
                  $(go.Picture, {
                    name: 'Info',
                    desiredSize: new go.Size(22, 22),
                    margin: 1.5,
                    // cursor: "pointer",
                    source: '/assets/images/list-white.svg', // the default image
                    // click: (e, button) => addInfo(button.part)
                  }),
                ),
              ),
              $(
                go.Panel,
                'Spot', // add custom node button
                {
                  name: 'customNodeBtn',
                  click: (e, button) => addCustomNode(button.part),
                  cursor: 'pointer',
                  margin: new go.Margin(0, 5, 4, 0),
                },
                $(go.Picture, {
                  desiredSize: new go.Size(34, 34),
                  source: '/assets/images/bubble.png', // the default image
                }),
                $(
                  go.Picture,
                  {
                    name: 'customNodeBtn',
                    // click: (e, button) => addCustomNode(button.part),
                    cursor: 'pointer',
                    desiredSize: new go.Size(15, 15),
                    margin: 1.5,
                    source: '/assets/images/add.png',
                  },
                  new go.Binding('source', 'info', findHeadShot),
                ),
              ),
            ), // end horizontal Panel

            $(
              go.Panel,
              'Spot', // scroll button
              {
                click: (e, button) => adjustTextBlockHeight(button.part),
                cursor: 'pointer',
                alignment: go.Spot.Right,
                row: 4,
                column: 0,
                columnSpan: 2,
                margin: new go.Margin(16, -5, 0, 0),
              },
              $(
                go.Picture,
                {
                  name: 'Info',
                  desiredSize: new go.Size(33, 33),
                  margin: 1.5,
                  // cursor: "pointer",
                  source: '/assets/images/scroll_down.svg', // the default image
                  click: (e, button) => addInfo(button.part),
                },
                new go.Binding('source', 'info', findHeadShot),
              ),
            ),
          ), // end Table Panel
        ), // end vertical Panel
        new go.Binding('opacity', 'isCommented', (sel) => (sel ? 1.0 : 0.0)).ofObject(),
      ), // end Auto Panel

      $(
        go.Panel,
        'Spot',
        {
          visible: false,
          alignment: go.Spot.Center,
          stretch: go.GraphObject.Fill,
          name: 'loader',
        },
        $(
          go.Shape,
          'RoundedRectangle',
          {
            fill: '#41464b5e',
            strokeWidth: 0,
            parameter1: 15,
          },
          new go.AnimationTrigger('angle'),
        ),

        $(
          go.Picture,
          {
            name: 'spinner',
            desiredSize: new go.Size(60, 60),
            // angle: 45,
            source: '/assets/images/spinner.svg', // the default image
            // source: SpinnerSvg, // the default image
          },
          new go.AnimationTrigger('angle'),
        ),
      ),

      $(
        go.Panel,
        'Spot',
        {
          alignment: go.Spot.Top,
        },
        $(go.Shape, 'Circle', {
          width: 50,
          // strokeWidth: 1.5,
          // stroke: '#fff',
          shadowVisible: true,
          fill: '#09091D',
        }),
        $(
          go.Picture,
          {
            name: 'Picture',
            desiredSize: new go.Size(26, 26),
            margin: 1,
            source: '/assets/images/home.svg', // the default image
          },
          new go.Binding('source', 'icon', findHeadShot),
        ),
      ),

      $(
        'Button',
        { visible: false },
        $(go.Shape, 'PlusLine', { width: 10, height: 10 }),
        {
          name: 'BUTTON',
          alignment: go.Spot.Right,
          opacity: 0, // initially not visible
          // click: (e, button) => addEmployee(button.part)
        },
        // button is visible either when node is selected or on mouse-over
        new go.Binding('opacity', 'isSelected', (s) => (s ? 1 : 0)).ofObject(),
      ),

      new go.Binding('isTreeExpanded').makeTwoWay(),
      // $(
      //   go.Panel,
      //   'Spot', // no links or grouping, so can use the simpler Part class
      //   new go.Binding('layerName', 'color'),
      //   new go.Binding('location', 'loc'),
      //   $(go.Shape, { width: 80, height: 80 }, new go.Binding('fill', 'color')),
      //   $(go.TextBlock, { stroke: 'white', font: 'bold 12px sans-serif' }),
      //   {
      //     selectionChanged: (p) => {
      //       p.layerName = p.isSelected ? 'Foreground' : p.data.color;
      //     },
      //     layerChanged: (p, oldLayer, newLayer) => {
      //       if (newLayer !== null) p.elt(1).text = newLayer.name;
      //     },
      //   },
      // ),
    ); // end Node, a Spot Panel
    // diagram.renderer = 'svg';

    // this is used to determine feedback during drags
    function mayWorkFor(node1, node2) {
      if (!(node1 instanceof go.Node)) return false; // must be a Node
      if (node1 === node2) return false; // cannot work for yourself
      if (node2.isInTreeOf(node1)) return false; // cannot work for someone who works for you
      return true;
    }

    function textStyle() {
      return { font: '9pt poppins, Helvetica, Arial, sans-serif', stroke: 'white' };
    }

    // We don't want the div acting as a context menu to have a (browser) context menu!
    cxElement.addEventListener(
      'contextmenu',
      (e) => {
        e.preventDefault();
        return false;
      },
      false,
    );

    function startSpinnerAnimation(node) {
      var picture = node.findObject('spinner');
      if (picture) {
        var animation = new go.Animation();
        animation.add(picture, 'angle', picture.angle, 360);
        animation.runCount = Infinity;
        animation.start();
        // var angle = picture.rotate ? picture.rotate + 1 : 1;
        // picture.rotate = angle % 360;
      }
    }

    const loadPythonScript = (prompt, category, node) => {
      hidePanel(node);

      startSpinnerAnimation(node);

      const loader = node.findObject('loader');
      loader.visible = true;

      const promptText = node.findObject('NAMETB');
      var prompt1 = promptText.text;
      var prompt = '';
      if (prompt1 == 'MORE INFORMATION' || prompt1 == 'EXAMPLES') {
        getParentPrompt(node);
        // prompt = $('#prompt').val();
      } else {
        // prompt = promptText.text;
      }
      // var category = category;
      // alert(node);
      var token = document.getElementById('token').value;
      // var token = '';
      var context = document.getElementById('context').value;
      var area = document.getElementById('area').value;

      // console.log("Area: " + area);
      // alert(context);
      const diagram2 = diagramRef.current.getDiagram();
      const jsonDecodedData = JSON.parse(diagram2.model.toJson());
      let counter = jsonDecodedData.nodeDataArray.length;
      // counter++;
      // setScriptCounter(scriptCounter + 1);

      if (counter === 1 && (category === 'INFO' || category === 'EXAMPLE')) {
        counter = 0;
        setScriptCounter(0);
      }

      var context0 = document.getElementById('context0').value;
      // alert(context0);
      if (counter > 1 && context0 !== '' && context0 !== undefined) {
        getAllParentPrompt(node);
        var context1 = document.getElementById('context1').value;

        var combinedArray = [];
        combinedArray.push(context0);
        combinedArray.push(context1);

        document.getElementById('context').value = `[${combinedArray}]`;
        var context = document.getElementById('context').value;
        area = '';
        // alert(context);
      }
      getMindMapResultsObj.mutate(
        {
          prompt,
          category,
          context,
          area,
          token,
        },
        {
          onSuccess: (data) => {
            var output = data.replace(/'/g, '"');
            // Replace single quotes outside sentences with double quotes
            var outputString = output.replace(/(\b\w+)"(\w+\b)/g, "$1'$2");
            var replacedStr = outputString.replace(/\(/g, '');

            var temp = replacedStr.replace(/"body":\s*""/, '"body": ""');
            var temp1 = temp.replace(/\\"/g, "\\'");

            var arrays = temp1.split(']');

            var temp = `${arrays[0]}]`;
            var startIndex = temp.indexOf('['); // Find the index of '['
            var result = temp.substring(startIndex);

            if (
              counter === 1 &&
              (document.getElementById('token').value === '' || document.getElementById('token').value === undefined) &&
              (document.getElementById('context0').value === '' ||
                document.getElementById('context0').value === undefined) &&
              category === 'IDEA'
            ) {
              var arrays1 = arrays[1].split('[');
              var temp = arrays1[0].replace(/,/g, '');

              var token = temp.trim();
              var inputString = `[${arrays1[1]}]`;

              var searchString = '{"main":"title of idea1","body":"body of idea1"}';
              var replacementString = '{\\"main\\":\\"title of idea1\\",\\"body\\":\\"body of idea1\\"}';

              var context0 = inputString.replace(searchString, replacementString);

              document.getElementById('token').value = token;
              document.getElementById('context0').value = context0 || '';
              var context = document.getElementById('context').value;

              console.log('Token:', arrays1, inputString, context0, context);
              console.log('Generated Parameters:');
              console.log(`Prompt: ${prompt}`);
              console.log(`Category: ${category}`);
              console.log(`Area: ${area}`);
              console.log(`Context: ${context}`, context0);
            } else {
              document.getElementById('token').value = token;
              var token = document.getElementById('token').value;
              var context0 = document.getElementById('context0').value;
              var context = document.getElementById('context').value;

              // console.log("Token:" + token);
              console.log('Generated Parameters:');
              console.log(`Prompt: ${prompt}`);
              console.log(`Category: ${category}`);
              console.log(`Area: ${area}`);
              // console.log("Context[0]: " + context0);
              // console.log("Context[1]: " + context1);
              console.log(`Context: ${context}`);
            }

            console.log('node, result', node, result, category);
            if (category == 'IDEA') {
              addEmployee(node, result);
            }
            if (category == 'INFO') {
              addInfo(node, result);
            }
            if (category == 'EXAMPLE') {
              addExample(node, result);
            }

            // $("#loader-div").fadeOut();
            loader.visible = false;
          },
        },
      );
      // axios
      //   .get(`http://localhost:3001/mind-map/script`, {
      //     params: {
      //       prompt,
      //       category,
      //       context,
      //       area,
      //       token,
      //     },
      //   })
      //   .then((data) => data.data)
      //   .then(function (data) {})
      //   .catch(function (error) {
      //     console.error(error);
      //   });

      if (!node) return;
    };

    const addCustomNode = (node) => {
      console.log('addCustomNode');
      if (!node) return;

      try {
        const thisemp = node.data;
        diagram.startTransaction('add employee');
        const newemp = {
          main: 'Your idea title here',
          body: 'Type description here...',
          icon: 'add-orange.svg',
          parent: thisemp.key,
        };
        diagram.model.addNodeData(newemp);
        const newnode = diagram.findNodeForData(newemp);
        if (newnode) newnode.location = node.location;

        const body = newnode.findObject('body');
        body.visible = true;
        body.editable = true;

        const title = newnode.findObject('NAMETB');
        title.editable = true;

        diagram.commitTransaction('add employee');
        diagram.commandHandler.scrollToPart(newnode);
        save(true);
      } catch (error) {
        toast.error(error.message, TOAST_CONFIG);
      }
    };

    function getAllParentPrompt(node) {
      var parentChain = [];

      while (node !== null) {
        parentChain.unshift(`"${node.findObject('NAMETB').text}"`);
        // node = node.findTreeParentNode();
      }

      parentChain.reverse();
      document.getElementById('context1').value = `[${parentChain}]`;
      // alert(document.getElementById("context1").value());
    }

    function getParentPrompt(node) {
      // alert(node);
      var parentNode = node.findTreeParentNode();
      // alert(parentNode);
      const promptText = parentNode.findObject('NAMETB');
      var prompt = promptText.text;
      if (prompt == 'MORE INFORMATION' || prompt == 'EXAMPLES') {
        getParentPrompt(parentNode);
      } else {
        $('#prompt').val(prompt);
      }
    }

    function addEmployee(node, output) {
      if (!node) return;

      try {
        var data = JSON.parse(output);

        console.log(`Outputemployee: ${output}`);
        // Iterate through the array using a for loop
        for (var i = 0; i < data.length; i++) {
          var item = data[i];
          const thisemp = node.data;
          diagram.startTransaction('add employee');
          const newemp = { main: item.main, information: item.body, icon: 'idea.svg', parent: thisemp.key };
          diagram.model.addNodeData(newemp);
          const newnode = diagram.findNodeForData(newemp);
          if (newnode) newnode.location = node.location;
          const body = newnode.findObject('body');
          body.visible = true;

          diagram.commitTransaction('add employee');
          diagram.commandHandler.scrollToPart(newnode);
        }
        save(true);
      } catch (error) {
        // Exception handling code
        // showAlertMessage("error", "Some error occured. Rerun the function again...")
      }
    }

    function addExample(node, output) {
      if (!node) return;

      // console.log(output);

      try {
        if (!output.includes('{"main": "*", "body": ""}') && !output.includes('{"main": "*", "body": " "}')) {
          // alert("hello");
          // var substringToRemove = '{"main": "*", "body":"';
          // var substringToRemove1 = '{"main": "*", "body": "}';

          var modifiedStr = output.replace(/\{"main": \*, "body":/g, '');
          var modifiedStr1 = modifiedStr.replace(/{"main": "*", "body":"/g, '');
          var modifiedStr2 = modifiedStr1.replace(/"}"}/g, '"}');
          var modifiedStr3 = modifiedStr2.replace(/" "/g, '"');
          var modifiedStr4 = modifiedStr3.replace(/""/g, '"');
          var modifiedStr5 = modifiedStr4.replace(/\\'/g, "'");
        } else {
          // alert("hi");
          var modifiedStr5 = output;
        }
        // console.log(modifiedStr5);
        var data = JSON.parse(modifiedStr5);

        console.log(`Output example: ${modifiedStr5}`);

        const examplesArray = new Array();
        var main = null;

        // Iterate through the array using a for loop
        for (var i = 0; i < data.length; i++) {
          var item = data[i];
          main = item.main;
          examplesArray[i] = item.body;
        }

        const thisemp = node.data;
        diagram.startTransaction('add employee');
        const newemp = { main: 'EXAMPLES', icon: 'list.svg', parent: thisemp.key, examples: examplesArray };
        diagram.model.addNodeData(newemp);
        const newnode = diagram.findNodeForData(newemp);
        if (newnode) newnode.location = node.location;
        const examples = newnode.findObject('examples');
        examples.visible = true;

        const generate = newnode.findObject('generate');
        generate.visible = false;
        const ideaBtn = newnode.findObject('ideaBtn');
        ideaBtn.visible = false;
        const infoBtn = newnode.findObject('infoBtn');
        infoBtn.visible = false;
        const exampleBtn = newnode.findObject('exampleBtn');
        exampleBtn.visible = false;

        const textBlock = newnode.findObject('textPanel');
        textBlock.maxSize = new go.Size(NaN, NaN);
        textBlock.minSize = new go.Size(NaN, NaN);
        // const add = newnode.findObject("add");
        // add.visible = "true";

        diagram.commitTransaction('add employee');
        diagram.commandHandler.scrollToPart(newnode);
        save(true);
        // console.log(examplesArray);
      } catch (error) {
        // Exception handling code
        // showAlertMessage("error", "Message was not in the right format. Rerun the function to get a different output")
      }
    }

    function addInfo(node, output) {
      if (!node) return;

      try {
        var modifiedContent = output.replace(/(\b\w+)"|(")/g, function (match, word, quote) {
          if (quote) {
            return '\\"';
          }
          return `${word}\\"`;
        });
        var modifiedStr = output.replace(/"{"main":/g, '');
        var modifiedStr1 = modifiedStr.replace(/"body":/g, '');
        var modifiedStr2 = modifiedStr1.replace(/"}/g, '');
        var modifiedStr3 = modifiedStr2.replace(/\\"/g, '"');
        var modifiedStr4 = modifiedStr3.replace(/\\'/g, "'");

        console.log(`Output Info: ${modifiedStr4}`);

        var data = JSON.parse(modifiedStr4);

        var paragraphs = data.join('\n');

        // console.log("Output: " + paragraphs);

        const thisemp = node.data;
        diagram.startTransaction('add employee');
        const newemp = {
          main: 'MORE INFORMATION',
          information: paragraphs,
          icon: 'question.svg',
          parent: thisemp.key,
        };
        diagram.model.addNodeData(newemp);
        const newnode = diagram.findNodeForData(newemp);
        if (newnode) newnode.location = node.location;
        const information = newnode.findObject('body');
        information.visible = true;

        const generate = newnode.findObject('generate');
        generate.visible = false;
        const ideaBtn = newnode.findObject('ideaBtn');
        ideaBtn.visible = false;
        const infoBtn = newnode.findObject('infoBtn');
        infoBtn.visible = false;
        const exampleBtn = newnode.findObject('exampleBtn');
        exampleBtn.visible = false;

        diagram.commitTransaction('add employee');
        diagram.commandHandler.scrollToPart(newnode);
        save(true);
      } catch (error) {
        // Exception handling code
        // showAlertMessage("error", "Message was not in the right format. Rerun the function to get a different output")
      }
    }

    function adjustTextBlockHeight(node) {
      const textBlock = node.findObject('textPanel');
      const { measuredBounds } = textBlock;
      const textBlockHeight = measuredBounds.height;

      if (textBlockHeight > 152) {
        textBlock.maxSize = new go.Size(measuredBounds.width, 152);
      } else {
        textBlock.maxSize = new go.Size(measuredBounds.width, NaN);
      }
    }

    function generate(node) {
      if (workSpaceAccess?.data?.role !== 'editor') return;
      if (!node) return;

      const generate = node.findObject('generate');

      if (generate.opacity == 1) {
        const ideaBtn = node.findObject('ideaBtn');
        const infoBtn = node.findObject('infoBtn');
        const exampleBtn = node.findObject('exampleBtn');
        const BtnPanel = node.findObject('BtnPanel');

        var animation = new go.Animation();
        animation.easing = go.Animation.EaseOutExpo;
        animation.duration = 1500;
        animation.add(generate, 'scale', 1, 0.00009);
        animation.add(generate, 'opacity', 1, 0);
        animation.start();

        if (!BtnPanel.visible) {
          BtnPanel.visible = true;
        }

        var animation1 = new go.Animation();
        animation1.easing = go.Animation.EaseOutExpo;
        animation1.duration = 1500;
        animation1.add(ideaBtn, 'scale', 0.00009, 1);
        animation1.add(ideaBtn, 'opacity', 0, 1);
        animation1.start();

        var animation2 = new go.Animation();
        animation2.easing = go.Animation.EaseOutExpo;
        animation2.duration = 1500;
        animation2.add(infoBtn, 'scale', 0.00009, 1);
        animation2.add(infoBtn, 'opacity', 0, 1);
        animation2.start();

        var animation3 = new go.Animation();
        animation3.easing = go.Animation.EaseOutExpo;
        animation3.duration = 1500;
        animation3.add(exampleBtn, 'scale', 0.00009, 1);
        animation3.add(exampleBtn, 'opacity', 0, 1);
        animation3.start();

        hideButtons(node);
      }
    }

    function hidePanel(node) {
      if (workSpaceAccess?.data?.role !== 'editor') return;

      const generate = node.findObject('generate');
      if (generate.opacity == 0) {
        const ideaBtn = node.findObject('ideaBtn');
        const infoBtn = node.findObject('infoBtn');
        const exampleBtn = node.findObject('exampleBtn');
        const BtnPanel = node.findObject('BtnPanel');

        var animation = new go.Animation();
        animation.easing = go.Animation.EaseOutExpo;
        animation.duration = 1500;
        animation.add(generate, 'scale', 0.1, 1);
        animation.add(generate, 'opacity', 0, 1);
        animation.start();

        var animation1 = new go.Animation();
        animation1.easing = go.Animation.EaseOutExpo;
        animation1.duration = 1500;
        animation1.add(ideaBtn, 'scale', 1, 0.00009);
        animation1.add(ideaBtn, 'opacity', 1, 0);
        animation1.start();

        var animation2 = new go.Animation();
        animation2.easing = go.Animation.EaseOutExpo;
        animation2.duration = 1500;
        animation2.add(infoBtn, 'scale', 1, 0.00009);
        animation2.add(infoBtn, 'opacity', 1, 0);
        animation2.start();

        var animation3 = new go.Animation();
        animation3.easing = go.Animation.EaseOutExpo;
        animation3.duration = 1500;
        animation3.add(exampleBtn, 'scale', 1, 0.00009);
        animation3.add(exampleBtn, 'opacity', 1, 0);
        animation3.start();

        // BtnPanel.visible = "false";
      }
    }

    function hideButtons(node) {
      setTimeout(function () {
        hidePanel(node);
      }, 5000);
    }

    function hideCX() {
      if (diagram.currentTool instanceof go.ContextMenuTool) {
        diagram.currentTool.doCancel();
      }
    }

    function showContextMenu(obj, diagram, tool) {
      // Show only the relevant buttons given the current state.
      var cmd = diagram.commandHandler;
      var hasMenuItem = false;
      function maybeShowItem(elt, pred) {
        if (pred) {
          // elt.style.display = 'block';
          hasMenuItem = true;
        } else {
          // elt.style.display = 'block';
          hasMenuItem = true;
        }
      }

      maybeShowItem(document.getElementById('delete'), cmd.canDeleteSelection());
      // maybeShowItem(document.getElementById("color"), obj !== null);

      // Now show the whole context menu element
      if (hasMenuItem) {
        cxElement.classList.add('show-menu');
        // we don't bother overriding positionContextMenu, we just do it here:
        var mousePt = diagram.lastInput.viewPoint;
        cxElement.style.left = `${mousePt.x + 5}px`;
        cxElement.style.top = `${mousePt.y}px`;
      }

      // Optional: Use a `window` pointerdown listener with event capture to
      //           remove the context menu if the user clicks elsewhere on the page
      window.addEventListener('pointerdown', hideCX, true);
    }

    function hideContextMenu() {
      cxElement.classList.remove('show-menu');
      // Optional: Use a `window` pointerdown listener with event capture to
      //           remove the context menu if the user clicks elsewhere on the page
      window.removeEventListener('pointerdown', hideCX, true);
    }

    // This converter is used by the Picture.
    function findHeadShot(icon) {
      if (!icon) return '/assets/images/home.svg';
      var result = `/assets/images/${icon}`;
      return result;
    }

    diagram.model = go.Model.fromJson(mapData);

    const { model } = diagram;
    const { nodeDataArray } = model;
    console.log('nodeDataArray', nodeDataArray);

    // const cdata = { from: 3, to: 2 };
    // diagram.model.addLinkData(cdata);
    return diagram;
  }

  return (
    <>
      <ul id="contextMenu" className="menu">
        <li id="delete" className="menu-item" onPointerDown={(event) => cxcommand(event)}>
          Delete Node
        </li>
      </ul>
      <ReactDiagram
        divClassName="diagram-component"
        initDiagram={initDiagram}
        // nodeDataArray={nodeDataArray}
        // linkDataArray={linkDataArray}
        skipsDiagramUpdate={skipsDiagramUpdate}
        onModelChange={handleModelChange}
      />

      <input type="number" id="token" name="token" defaultValue="" hidden />
      <input type="text" id="context" name="context" defaultValue="" hidden />
      <input type="text" id="context0" name="context0" defaultValue="" hidden />
      <input type="text" id="context1" name="context1" defaultValue="" hidden />
      <input type="text" id="area" name="area" defaultValue="" hidden />
      <input type="text" id="prompt" defaultValue="" hidden />
      <input type="text" id="heritage" defaultValue="" hidden />
      <textarea id="mySavedModel" hidden />
    </>
  );
};

export default TestPage;
