import React from 'react';
import PaymentSuccessUiFetures from 'fetures/PaymentSuccessUI';

import './style.css';

const PaymentSuccessUiPage = () => {
  return (
    <div>
      <PaymentSuccessUiFetures />
    </div>
  );
};

export default PaymentSuccessUiPage;
