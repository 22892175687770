import React from 'react';
import MindMap from 'fetures/MindMap';
import PageTitle from 'components/PageTitle';

const MindMapPage = () => {
  return (
    <>
      <PageTitle title="Mind-Map" />
      <MindMap />
    </>
  );
};

export default MindMapPage;
