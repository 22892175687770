import React from 'react';
import GenerateLinkComponent from 'fetures/GenerateLink';
import PageTitle from 'components/PageTitle';

const GenerateAndRefrelLink = () => {
  return (
    <>
      {/* <PageTitle title="Generate Link" /> */}

      <GenerateLinkComponent />
    </>
  );
};

export default GenerateAndRefrelLink;
