import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { routes } from 'constant';
// eslint-disable-next-line import/order
import PropTypes from 'prop-types';
import { ModalComponent } from 'components';
import heroSec from 'assets/hero-sec.png';
import mainVid from 'assets/ideon_main_v5.mp4';

import 'bootstrap/dist/css/bootstrap.css';
import './style.css';

const CollaborativeAIBrainstorming = ({ resultRef }) => {
  const [modalShow, setModalShow] = useState(false);

  const handleClick = () => {
    resultRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const modalOpen = () => {
    setModalShow(true);
  };

  const modalClose = () => {
    setModalShow(false);
  };

  return (
    <div className="md_vishual_wrapper">
      <div>
        <h2 className="md_title_text home_page_revolution_title ">AI-Powered visual thinking platform</h2>
        <h2 className="md_sub_title_text child_titles_text">for tech companies</h2>
      </div>
      <div className="row md_banner_wrapper">
        <div className="col-lg-6">
          <div className="responsive_collaborative_content ">
            <div>
              <p className="md_p_width md_p_width_tech text-white text_btns_div_p mb-0">
                Faster, high-quality process to turn <br />
                challenges into opportunities.
              </p>
            </div>
            <div>
              <h2 className="md_trust-text child_titles_text">
                <FaCheck className="checked_svg" /> Market validation and customer acquisition
              </h2>
              <h2 className="md_trust-text child_titles_text">
                <FaCheck className="checked_svg" /> Product development and optimisation
              </h2>
              <h2 className="md_trust-text child_titles_text">
                <FaCheck className="checked_svg" /> Data analysis and business intelligence
              </h2>
              <h2 className="md_trust-text child_titles_text">
                <FaCheck className="checked_svg" /> Competitive landscape and risk management
              </h2>
            </div>
            <div className="md_text_btns_div md_text_btns_div_tech">
              <div className="btns_flex_div">
                {/* <span> */}
                <Link to={routes.register} className="trial_btn">
                  Start free trial
                </Link>
                {/* </span>
            <span> */}
                <Link to="https://app.lemcal.com/@ideon/demo" className="book_btn">
                  REQUEST A DEMO
                </Link>
                {/* </span> */}
              </div>
              <span className="md_italilc_text text-white">*No card required</span>
            </div>
            {modalShow === true && <ModalComponent onClose={modalClose} />}
          </div>
        </div>
        <div className="col-lg-6 main-img-resolution main-img-resolution_tech">
          {/* <img src={heroSec} alt="homrBanner" className="w-100 responsive_collaborative_image md_hero_banner_img" /> */}
          <video
            autoPlay
            loop
            muted
            playsInline
            className="w-full w-100 responsive_collaborative_video responsive_collaborative_image"
          >
            <source src={mainVid} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};
CollaborativeAIBrainstorming.defaultProps = {
  resultRef: PropTypes.object,
};
CollaborativeAIBrainstorming.propTypes = {
  resultRef: PropTypes.object,
};

export default CollaborativeAIBrainstorming;
