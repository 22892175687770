import React from 'react';
import ContactComponent from 'fetures/Contact';
import PageTitle from 'components/PageTitle';

const index = () => {
  return (
    <>
      <PageTitle title="Contact Us" />

      <ContactComponent />
    </>
  );
};

export default index;
