import Api from './axios.config';

export const getAllCollabrater = async (obj) => {
  return new Promise((resolve) => {
    Api.get(`collabrator?work_space_id=${obj.queryKey}`).then((res) => {
      resolve(res.data || {});
    });
  });
};

export const deleteCollabraterUser = async (id) => {
  const response = await Api.delete('/collabrator', { data: { id } });
  return response.data;
};

export const addCollabrater = async (data) => {
  const response = await Api.post('/collabrator', data);
  return response.data;
};

export const createCollabraterShareLink = async (data) => {
  const response = await Api.post('/collabrator/create-link', data);
  return response.data;
};
