import React from 'react';
import DashBoardComponent from 'fetures/DashBoard';
import PageTitle from 'components/PageTitle';

const DashBoardPage = () => {
  return (
    <>
      <PageTitle title="Dashboard" />

      <DashBoardComponent />
    </>
  );
};

export default DashBoardPage;
