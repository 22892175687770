import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const BorderButton = ({ children, onClick }) => {
  return (
    <button type="button" onClick={onClick} className="submitBorderButton">
      {children}
    </button>
  );
};

BorderButton.defaultProps = {
  onClick: '',
};

BorderButton.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
};
export default BorderButton;
