/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { AiOutlinePlus } from 'react-icons/ai';
import { RiCloseLine } from 'react-icons/ri';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { InputBox } from 'components';
import { AddComments, GetComments } from 'api/mindMamp';
import { CommentInitialValues, CommentValidation } from './constant';

import './style.css';

const CommentModal = ({ commentNodeData, handleClose }) => {
  const formRef = useRef();

  const {
    data: comments,
    isLoading: commentsListLoader,
    refetch,
  } = useQuery({
    queryKey: [commentNodeData],
    queryFn: GetComments,
    onError: (err) => {
      catchBlock(err);
    },
  });

  const { mutate: AddCommentFun, isLoading: cmtPostLoader } = useMutation(AddComments, {
    onSuccess: (data, variables) => {
      refetch();
      toast.success(data.message);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const SubmitComment = useCallback(
    (values) => {
      const addCommentRequestVal = {
        work_space_id: commentNodeData.work_space_id,
        node_id: commentNodeData.node_id,
        comment: values.comment,
      };

      AddCommentFun(addCommentRequestVal, {
        onSuccess: () => {
          if (formRef.current) {
            formRef.current.resetForm();
          }
        },
      });
    },
    [AddCommentFun, commentNodeData.node_id, commentNodeData.work_space_id],
  );

  return (
    <div className="modal-container">
      <div className="modal-heading">
        <h6 className="modal-heading-title">Detail</h6>
        <button type="button" onClick={() => handleClose()} className="modal-heading-btn">
          <RiCloseLine color="#f05436" size="25" fontWeight="bold" />
        </button>
      </div>

      <div className="modal-body-form-container">
        <p className="modal-body-title">Comments</p>
        {commentsListLoader ? (
          <div className="no-data-div">
            <Spinner animation="border" className="spiner" />
          </div>
        ) : comments.data === undefined || comments.data.length === 0 ? (
          <div className="no-data-div">
            <h4 className="workspace-modal-h4">No Comments !!!</h4>
          </div>
        ) : (
          comments?.data?.map((cmt) => {
            return (
              <div key={cmt._id} className="modal-body-comment-div">
                <p className="modal-body-bold-p">{cmt.user_id.email} :</p>
                <p className="modal-body-semibold-p">{cmt.comment}</p>
              </div>
            );
          })
        )}

        <div className="modal-body-form">
          <div className="modal-body-formik-form">
            <Formik
              initialValues={CommentInitialValues}
              validationSchema={CommentValidation}
              onSubmit={SubmitComment}
              innerRef={formRef}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="modal-form">
                    <div className="modal-input-div">
                      <Field
                        placeholder="Comment..."
                        name="comment"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.comment}
                        component={InputBox}
                        customClass="modal-input-style"
                        type="text"
                      />
                      {errors.comment && touched.comment ? (
                        <div className="lightRed d-block  justify-content-center mx-auto  text-start">
                          {errors.comment}
                        </div>
                      ) : null}
                    </div>

                    <button type="submit" className="modal-form-btn">
                      {cmtPostLoader ? <Spinner animation="border" size="sm" /> : 'Send'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <div className="modal-body-attachment-container" style={{ display: 'none' }}>
        <p className="modal-body-title">Attachments</p>

        <div className="modal-attachment-grid-div">
          <div className="modal-attachment-btn-div">
            <AiOutlinePlus color="#fff" size="23" fontWeight="bold" className="modal-attachment-plus-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

CommentModal.defaultProps = {
  handleClose: PropTypes.func,
  commentNodeData: PropTypes.object,
};

CommentModal.propTypes = {
  handleClose: PropTypes.func,
  commentNodeData: PropTypes.object,
};
export default CommentModal;
