import React, { useRef } from 'react';
import GetStartedWithIdeonToday from 'components/GetStartedWithIdeonToday';
import {
  CollaborativeAIBrainstorming,
  IntroducingaNewWayOfIdeation,
  TailoredExperienceForYourNeeds,
  ThreeStepstoYourNextIngeniousIdea,
} from './Components';

import './style.css';

const Home = () => {
  const resultRef = useRef();

  return (
    <div className="width_89 justify-content-center m-auto ">
      <div className="container">
        <CollaborativeAIBrainstorming resultRef={resultRef} />

        <IntroducingaNewWayOfIdeation />

        <TailoredExperienceForYourNeeds />
      </div>
      {/* <div ref={resultRef}>
        <ThreeStepstoYourNextIngeniousIdea />
      </div> */}

      {/* <GetStartedWithIdeonToday /> */}
    </div>
  );
};

export default Home;
