import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import './style.css';

const InputBox = ({ placeholder, field, customClass, type, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [localStateVal, setLocalStateVal] = useState(field.value);

  useEffect(() => {
    setLocalStateVal(field.value);
  }, [field.value]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFieldValue(field.name, localStateVal);
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [field.name, localStateVal, setFieldValue]);

  return (
    <input
      className={`d-block justify-content-center fs-7 my-3 inputBoxStyle ${customClass}`}
      placeholder={placeholder}
      {...field}
      {...props}
      type={type}
      value={localStateVal}
      onChange={(e) => setLocalStateVal(e.target.value)}
    />
  );
};

InputBox.defaultProps = {
  placeholder: PropTypes.string,
  field: PropTypes.any,
  customClass: PropTypes.any,
};

InputBox.propTypes = {
  placeholder: PropTypes.string,
  field: PropTypes.any,
  type: PropTypes.string.isRequired,
  customClass: PropTypes.any,
};

export default InputBox;
