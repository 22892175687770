// eslint-disable-next-line import/no-extraneous-dependencies
import { configureStore } from '@reduxjs/toolkit';
import workspaceSlice from 'reducers/workspaceSlice';

const store = configureStore({
  reducer: {
    workspace: workspaceSlice,
  },
});

export default store;
