import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'constant';

import './style.css';

const TextTitleLogo = () => {
  return (
    <div>
      <Link to={routes.home} className="text-decoration-none">
        <h4 className="titleLogoText">
          Ideon<span>AI</span>
        </h4>
      </Link>
    </div>
  );
};

export default TextTitleLogo;
