// eslint-disable-next-line simple-import-sort/imports
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { Field, Form, Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { FaCopy } from 'react-icons/fa';
import { TOAST_CONFIG } from 'constant';
import { InputBox } from 'components';
import { referralLinkApi } from 'api/referralLink';
import { catchBlock } from 'utils';
import { GenerateLinkInitialValues, GenerateLinkValidation } from './constant';

import './style.css';

const GenerateLinkComponent = () => {
  const formRef = useRef();
  const location = useLocation();
  const [generatedLink, setGeneratedLink] = useState('');
  const randomStringGenerate = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const { mutate: postReferralLink, isLoading } = useMutation(referralLinkApi, {
    onSuccess: (res) => {
      toast.success(res.message, TOAST_CONFIG);
      setGeneratedLink(`${window.location.origin}/referral/${res.data.referralCode}`);
      if (formRef.current) {
        formRef.current.resetForm();
      }
    },
    onError: (error) => {
      catchBlock(error);
      toast.error('Invalid Data', TOAST_CONFIG);
    },
  });

  const handleSubmitFun = (values) => {
    postReferralLink(values);
  };

  return (
    <div className="generate-link-page">
      <div className="generate-link-page-container">
        <Formik
          initialValues={GenerateLinkInitialValues}
          validationSchema={GenerateLinkValidation}
          onSubmit={handleSubmitFun}
          innerRef={formRef}
        >
          {({ values, setFieldValue, errors, touched, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div className="mt-3">
                <Field
                  type="text"
                  customClass="generate-link-page-container-inputs-div-text-input"
                  component={InputBox}
                  placeholder="Enter Referral Code"
                  name="referralCode"
                  value={values.referralCode}
                />
                {errors.referralCode && touched.referralCode ? (
                  <div className="d-bloc text-start Error_msg_text">{errors.referralCode}</div>
                ) : null}
              </div>

              <div className="mt-3">
                <Field
                  type="number"
                  customClass="generate-link-page-container-inputs-div-text-input"
                  component={InputBox}
                  placeholder="Enter Trial Days"
                  name="timePeriod"
                  value={values.timePeriod}
                />
                {errors.timePeriod && touched.timePeriod ? (
                  <div className="d-block mb-1 text-start Error_msg_text">{errors.timePeriod}</div>
                ) : null}
              </div>

              <div className="mt-3">
                <div className="generate-link-page-container-inputs-div-buttons">
                  <button
                    type="button"
                    className="generate-link-page-container-inputs-div-string-generate"
                    onClick={() => {
                      setFieldValue('referralCode', randomStringGenerate(15));
                    }}
                  >
                    Customization
                  </button>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="generate-link-page-container-inputs-div-string-generate"
                  >
                    Generate Link
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {generatedLink !== '' && (
        <div className="generate-link-page-container-cretaed-link">
          {generatedLink}
          <FaCopy
            size={25}
            className="mx-2 clipbpard-icon"
            text="copy"
            onClick={() => {
              navigator.clipboard.writeText(generatedLink);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default GenerateLinkComponent;
