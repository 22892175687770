/* eslint-disable import/order */
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'constant';
import { FormHeading, TitleLogo } from 'components';

import './style.css';

const PaymentFail = () => {
  return (
    <div className="login_page_height">
      <div className="login_background login_padding_bottom  ">
        <div className="login_logo_div">
          <TitleLogo />
        </div>

        <div className=" login_width_45 justify-content-center align-items-center mx-auto text-center my-5 pt-2">
          <FormHeading>Payment Fail</FormHeading>
          <Link to={routes.stripeDemo}>Try Again</Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentFail;
