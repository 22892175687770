/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

export const InviteSubValidation = Yup.object()
  .shape({
    emailId: Yup.string()
      .email('Please provide valid email!')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Please enter email!'),
    slot: Yup.string(),
  })
  .defined();

export const InviteSubInitialValues = {
  emailId: '',
  slot: 1,
};
