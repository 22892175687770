import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { routes, TOAST_CONFIG } from 'constant';
// eslint-disable-next-line import/order
import { Field, Form, Formik } from 'formik';
// import { useNavigate } from 'react-router-dom';
import { Button, InputBox, TextTitleLogo, TitleLogo } from 'components';
import PageTitle from 'components/PageTitle';
import { forgotPasswordPost } from 'api/auth';
import { catchBlock } from 'utils';
import { initialValues, validationSchema } from './constant';

import './style.css';

const ForgotPasswordPage = () => {
  const formRef = useRef();
  const { mutate: forgotPasswordPostMutate, isLoading } = useMutation(forgotPasswordPost, {
    onSuccess: (data) => {
      toast.success(data.message, TOAST_CONFIG);
      if (formRef.current) {
        formRef.current.resetForm();
      }
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const forgotPasswordSubmitHandler = (values) => {
    const valueObj = {
      email: values.email,
    };
    forgotPasswordPostMutate(valueObj);
  };

  return (
    <>
      <PageTitle title="Forgot Password" />

      <div className="forgot_background forgot_page_height ">
        <div className="forgot_logo_div">
          <TextTitleLogo />
        </div>

        <div className="forgont_width justify-content-center align-items-center mx-auto text-center my-5 pt-2">
          <h1 className="forgot_font_family forgot_lightRed forgot_font_size_48 forgot_margin_top">Forgot Password</h1>

          <div className="pb-4 ps-4 pe-4 forgot_formik_div">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={forgotPasswordSubmitHandler}
              innerRef={formRef}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Field
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    onBlur={handleBlur}
                    component={InputBox}
                  />
                  {errors.email && touched.email ? (
                    <div className="forgot_lightRed d-block justify-content-start mx-auto text-start">
                      {errors.email}
                    </div>
                  ) : null}
                  <Button disabled={isLoading}>{isLoading ? 'Loading...' : 'Send Password Reset Link'}</Button>
                  <h6 className="text-white mt-3">
                    Do you know the password?{' '}
                    <Link to={routes.login} className="forgot_lightRed logInLink">
                      Log In
                    </Link>
                  </h6>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
