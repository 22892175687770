import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { COMPANY_PROFILE_REDIRECT_URL_KEY, routes } from 'constant';
import { TopNavigationBar } from 'components';
import { getCompanyProfile } from 'api/companyProfile';
import { getUserSubscription } from 'api/payment';
import { catchBlock } from 'utils';
import { FormComponent } from './Components';

import './style.css';

const MindMap = () => {
  const navigate = useNavigate();

  const { mutate: fetchUserSub, isFetching: isFetchingUserSubscription } = useQuery({
    queryKey: ['user-subscription-expire'],
    queryFn: getUserSubscription,
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (
        data.data &&
        data.data.status !== 'active' &&
        data.data.status !== 'free_trial' &&
        data.data.status !== 'trialing'
      ) {
        navigate(routes.stripeEmbedDemo, { replace: true });
      }
    },
    onError: (err) => {
      catchBlock(err);
    },
  });

  const { isFetching: isLoadingCompanyProfile } = useQuery(
    ['workspace-get-company-profile'],
    () => getCompanyProfile(),
    {
      refetchOnMount: 'always',
      onSuccess: (response) => {
        if ((response?.data || []).length <= 0) {
          localStorage.setItem(COMPANY_PROFILE_REDIRECT_URL_KEY, routes.mindMap);
          setTimeout(() => {
            navigate(routes.companyProfile);
          }, 0);
        } else if (fetchUserSub) fetchUserSub();
      },
      onError: (err) => {
        catchBlock(err);
      },
    },
  );

  if (isFetchingUserSubscription || isLoadingCompanyProfile) {
    return (
      <div className="spinner-div">
        <Spinner animation="border" className="spiner" />
      </div>
    );
  }

  return (
    <div className="bg-transparent mindmap_Main_Container">
      <TopNavigationBar isShowCompanyDropdown logoLink={routes.dashBoard} />

      <div className="bg-transparent mm_form-wrapper mindMamppage">
        <FormComponent />
      </div>
    </div>
  );
};

export default MindMap;
