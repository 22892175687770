import React from 'react';
import contact from 'assets/contact.png';

import './style.css';

const ContactImage = () => {
  return (
    <div className="responsive_image_contanct">
      <img src={contact} alt="contact" className="resposive_peta_image_width" />
    </div>
  );
};

export default ContactImage;
