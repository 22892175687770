import React from 'react';
import { BsFillPersonFill } from 'react-icons/bs';

// import PropTypes from 'prop-types';
import './style.css';

const CurrentWorkSpaceModal = () => {
  return (
    <div className="">
      <div className="workspace_modal_main_container">
        <div className="workspace_hearder">
          <h1 className="ideon_modal_heading">Ideon</h1>
          <h1 className="ideon_modal_heading idean_person_icon_text">
            <BsFillPersonFill />
          </h1>
        </div>
        <div className="current_workspace_background_color">
          <h6 className="current_workspace_text">Current Workspace</h6>
          <h1 className="current_workspace_heading_font">HOW TO INVEST</h1>
        </div>
        <button className="myworkspace_button" type="button">
          My Workpaces
        </button>
      </div>
    </div>
  );
};

// CurrentWorkSpaceModal.defaultProps = {
//   handleClose: PropTypes.func,
// };

// CurrentWorkSpaceModal.propTypes = {
//   handleClose: PropTypes.func,
// };

export default CurrentWorkSpaceModal;
