import React from 'react';
import { Display, Mobile } from './Components';

import './Style.css';

const ToggleDropDown = () => {
  return (
    <div className="selectBoxDiv">
      <Mobile />

      <Display />
    </div>
  );
};

export default ToggleDropDown;
