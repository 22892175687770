import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { ACCESS_TOKEN_LOCAL_STORAGE_KEY, routes, USER_DATA_LOCAL_STORAGE_KEY } from 'constant';
import { getUserSubscription } from 'api/payment';

import './style.css';

const EmbedStripeDemo = () => {
  const navigate = useNavigate();
  const loginUser = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  const userData = JSON.parse(localStorage.getItem(USER_DATA_LOCAL_STORAGE_KEY));

  const [email] = useState(userData?.email);
  const [isLogin, setIsLogin] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);

  const { mutate: getUserSubscriptionMutate, isLoading: isFetchingUserSubscription } = useMutation(
    getUserSubscription,
    {
      onSuccess: (data) => {
        if (
          data.data &&
          (data.data.status === 'unpaid' || data.data.status === 'expired' || data.data.status === 'free_trial')
        ) {
          setIsSubscribed(false);
        }
      },
      onError: (err) => {
        catchBlock(err);
      },
    },
  );
  useEffect(() => {
    if (loginUser !== null) {
      setIsLogin(true);
      getUserSubscriptionMutate();
    }
  }, [getUserSubscriptionMutate, loginUser]);

  const redirectLogin = () => {
    if (!isLogin) navigate(routes.login);
  };
  return (
    <div className="App">
      <div
        // eslint-disable-next-line no-nested-ternary
        className={!isLogin ? 'overlayDivForLogin' : isSubscribed ? 'overlayDivForSubscribed' : ''}
        onClick={() => redirectLogin()}
      >
        <stripe-pricing-table
          pricing-table-id={process.env.REACT_APP_STRIPE_PRICE_TABLE_ID}
          publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
          customer-email={email || ''}
          allow-top-navigation
        />
      </div>
    </div>
  );
};

export default EmbedStripeDemo;
