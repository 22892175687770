import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const FormHeading = ({ children }) => {
  return <h1 className="form_heading_fontfamily form_heading_lightRed login_fontSize mt-3">{children}</h1>;
};

FormHeading.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormHeading;
