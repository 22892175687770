import * as Yup from 'yup';

export const GenerateLinkValidation = Yup.object().shape({
  referralCode: Yup.string().required('Please enter referral code ').min(10, 'Minimum text length is 10'),
  timePeriod: Yup.number().max(60, 'Maximum trial days is 60'),
});

export const GenerateLinkInitialValues = {
  referralCode: '',
  timePeriod: '',
};
