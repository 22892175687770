import React from 'react';
import { useQuery } from '@tanstack/react-query';
import getStripe from 'lib/getStripe';
import { priceDetailsQuery } from 'api/payment';
import { catchBlock, getUserData } from 'utils';

const StripeDemo = () => {
  const userData = getUserData();
  const { data: priceDetails, isFetching } = useQuery({
    queryKey: ['fetch-stripe-price-details'],
    queryFn: priceDetailsQuery,
    onError: (error) => {
      catchBlock(error);
    },
  });
  const handleCheckout = async () => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: process.env.REACT_APP_STRIPE_PRICE_ID,
          quantity: 1,
        },
      ],
      mode: 'payment',
      successUrl: `${process.env.REACT_APP_BASE_URL}/payment-success`,
      cancelUrl: `${process.env.REACT_APP_BASE_URL}/payment-fail`,
      customerEmail: userData.email,
    });
    if (error) {
      catchBlock(error);
    }
  };

  return (
    <div className="stripe_height">
      {isFetching && <h3 className="text-white">Loading...</h3>}
      {!isFetching && (
        <>
          <h3 className="text-white">{priceDetails.data.product.name}</h3>
          <h3 className="text-white">${(priceDetails.data.price.unit_amount / 100).toFixed(2)}</h3>
          <button className="payment-button" type="button" onClick={() => handleCheckout()}>
            PAY
          </button>
        </>
      )}
    </div>
  );
};

export default StripeDemo;
